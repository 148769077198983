import {db} from "./db";
import {lang} from "../components/appLang";
import { warning } from "../components/Notifications";
import { checkInput } from "../components/FormComponents";
import { recordException } from "../components/dbSections";
import { checkForLang } from "./tools";

const Lang = lang();

function getJobStatus (taskResults:any, jobTasks:any,  meterDetails:any, meter_type:string, setJobStatus:any) {
	if ( jobTasks ) {
		const updated_at = new Date();
		const jobComplete: any = [];
		for ( let i=0;i<jobTasks.length;i++ ) {
			if ( jobTasks[i].active && (jobTasks[i].minor_type === "SuiteInstall" || jobTasks[i].minor_type === null)  ) { // make sure it's the right minor tasks
				if ( jobTasks[i].sub_type === null || jobTasks[i].sub_type === meter_type ) {
					let desc = jobTasks[i].field;
					if ( jobTasks[i].field in Lang ) {
						const langName = checkForLang(jobTasks[i].field);
						desc = langName;
					}
					let restrictDescription = "";
					if ( jobTasks[i].field_type === "radio" || jobTasks[i].field_type === "checkbox" ) {
						const restrictParts = jobTasks[i].restriction.split(",");
						for ( let j=0;j<restrictParts.length;j++ ) {
							if ( restrictParts[j] in Lang ) {
								const langName = checkForLang(restrictParts[j]);
								restrictDescription = restrictDescription+langName+",";
							}
							else {
								restrictDescription = restrictDescription+restrictParts[j]+",";
							}
						}
					}
					jobComplete.push({
						"id": jobTasks[i].id,
						"field": jobTasks[i].field,
						"screenName": desc,
						"field_type": jobTasks[i].field_type,
						"restriction": jobTasks[i].restriction,
						"screenRestriction": restrictDescription,
						"mandatory": jobTasks[i].mandatory,
						"ex_allowed": jobTasks[i].exception_allowed,
						"status": false,
						"value": "",
						"position": jobTasks[i].position,
						"hold": jobTasks[i].hold
					});
				}
			}
		}
		db.jobDetails.where({client_id: taskResults.client_id, property_id: taskResults.location, job_type: taskResults.majorTask, meter_id: meterDetails.meter_id}).toArray().then((result) => {
			if ( result.length === 0 ) {
				db.jobDetails.add({
					client_id: Number(taskResults.client_id), 
					property_id: Number(taskResults.location),
					job_type: String(taskResults.majorTask),
					serial_number: String(meterDetails.serial_number),
					meter_id: Number(meterDetails.meter_id),
					suite: String(taskResults.suite),
					details: [],
					updated_at: updated_at
				});
			}
			for ( let i=0;i<result.length;i++ ) {
				if ( result[i].job_type === taskResults.majorTask ) {
					for ( let d=0;d<result[i].details.length;d++ ) {
						for ( let j=0;j<jobComplete.length;j++ ) {
							if ( result[i].details[d].field === jobComplete[j].field ) {
								jobComplete[j].status = result[i].details[d].status;
								jobComplete[j].value = result[i].details[d].value;
								j = jobComplete.length;
							}
						}
					
					}
				}
			}
			setJobStatus(jobComplete); 
		});
		
	}
}

function updateJobStatus (taskResults:any, meterDetails:any, field:string, value:any, exception:string, jobStatus:any, setJobStatus:any, bypass:boolean) {
	const updated_at = new Date();
	const newStatus = [];
	const newJobDetails = [];
	let status = false;
	for ( let i=0; i<jobStatus.length; i++ ) {
		if ( jobStatus[i].field === field ) {
			if ( bypass ) {
				status = checkInput("text", "alpha,240",value);
				console.log("checking bypass input",status,value);
			}
			else {
				status = checkInput(jobStatus[i].field_type, jobStatus[i].restriction,value);
			}
			
			if ( !status ) {
				value = "";
				return(status);
			}
			if ( jobStatus[i].value !== "" && jobStatus[i].ex_allowed === true && jobStatus[i].value !== value ) {
				console.log("exception found", exception);
				if ( !checkInput("text", "alpha,240", exception) ) {
					warning(Lang.exceptionExpectedGeneral);
					return false;
				}
				recordException(taskResults.client_id,taskResults.location,taskResults.suite,taskResults.employee_id,Number(meterDetails.meter_id),jobStatus[i].field,jobStatus[i].value,value,exception);
			}
			else if ( bypass ) {
				console.log("bypass", value);
				value = Lang.bypassLabel + value;
				recordException(taskResults.client_id,taskResults.location,taskResults.suite,taskResults.employee_id,Number(meterDetails.meter_id),jobStatus[i].field,"","",value);
			}
			newStatus.push({
				"id": jobStatus[i].id,
				"field": jobStatus[i].field,
				"screenName": jobStatus[i].screenName,
				"field_type": jobStatus[i].field_type,
				"restriction": jobStatus[i].restriction,
				"screenRestriction": jobStatus[i].screenRestriction,
				"mandatory": jobStatus[i].mandatory,
				"ex_allowed": jobStatus[i].ex_allowed,
				"status": status,
				"value": value,
				"position": jobStatus[i].position,
				"hold": jobStatus[i].hold
			});
			newJobDetails.push({
				"field": jobStatus[i].field,
				"status": status,
				"value": value
			});
		}
		else {
			newStatus.push(jobStatus[i]);
			newJobDetails.push({
				"field": jobStatus[i].field,
				"status": jobStatus[i].status,
				"value": jobStatus[i].value
			});
		}
	}
	db.jobDetails.where({
		client_id: Number(taskResults.client_id), 
		property_id: Number(taskResults.location),
		job_type: String(taskResults.majorTask),
		meter_id: Number(meterDetails.meter_id)})
		.modify({
			details: newJobDetails,
			updated_at: updated_at
		});
	setJobStatus(newStatus);
	return status;
}

export { getJobStatus, updateJobStatus};