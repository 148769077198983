import React, { useEffect, useState } from "react";
import { db } from "../services/db";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export const PwaStatus: React.FC<Props> = () => {
	const [swStatus, setSwStatus] = useState(false);
	const [cachesContent, setCachesContent] = useState<string | string[]>("No Cache Found");
	const [sWTest, setSWTest] = useState<any>();

	useEffect(() => {
		const set = async () => {
			if ("serviceWorker" in navigator) {
				await navigator.serviceWorker.ready.then((registration) => {
					setSwStatus(true);
				});
				caches.keys().then((cacheNames) => {
					setCachesContent(cacheNames);
				});
			}
		};
		set();
		db.testTable.get({id: 1}).then((values) => {
			setSWTest(values?.updated.toString());
		});
	}, []);

	return (
		<div style={{ display: "flex", flexDirection: "column", color: "white", background: "gray", margin: "auto" }}>
			<div>Service Worker Status</div>
			{swStatus ? <div style={{ color: "green" }}>ServiceWorker is Active</div> : <div style={{ color: "red" }}> ServiceWorker is Offline</div>}
			<div>Caches</div>
			{cachesContent === "No Cache Found" ? <div style={{ color: "red" }}>{cachesContent}</div> : <div style={{ color: "green" }}>{cachesContent}</div>}
			<div>{sWTest}</div>
		</div>
	);
};