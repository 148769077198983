/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { warning } from "./Notifications";
import {lang} from "../components/appLang";
import { numberOnlyInput } from "../services/tools";

const Lang = lang();

const ButtonComponent = (props: any) => {
	let classname="pointer basicbutton ";
	if ( props.classname ){
		classname=classname+props.classname;
	}
	return <button type="button" onClick={props.action} className={classname}>{props.buttonText}</button>;
};

const Radio = ({radioName, setValue, value, screenName}:any) => {
	const [radioCheck, setRadioCheck] = useState(false);
	useEffect (() => {
		if ( value === radioName) {
			setRadioCheck(true);
		}
		else {
			setRadioCheck(false);
		}
	},[value]);

	function clickRadio (form_value:any) {
		setValue(form_value);
	}

	return (<div>
		<div className="radioButtons"><input 
			name={radioName}
			type="radio"
			onChange={(e) => {clickRadio(e.target.value);}}
			className="radio"
			value={radioName}
			checked={radioCheck}/>
		</div>
		{screenName}
	</div>);
};

const Checkbox = ({ label, checked, formUpdate, ...props }:any ) => {
	const defaultChecked = checked ? checked : false;
	const [isChecked, setIsChecked] = useState(defaultChecked);
	useEffect (() => {
		setIsChecked(checked);
	},[defaultChecked]);

	function setCheck (value:any) {
		console.log(value);
		setIsChecked(value);
		formUpdate(value);
	}
	return (
		<div className="checkbox-wrapper">
			<div className="checkboxDiv">
				<input 
					type="checkbox" 
					checked={isChecked}
					onChange={() => {setCheck(!isChecked);}}
					className={isChecked ? "checked" : ""}
					{...props}
				/>
			</div>
			<div className="formLabel">{label}</div>
		</div>
	);
};

//(prev: boolean) => !prev

function checkInput(checkType:string, restriction:string, value:any) {
	let status = false;
	const restrictValues:(string|number)[] = restriction.split(",");
	if ( checkType === "radio" ) {
		for ( let i=0;i<restrictValues.length;i++ ) {
			if ( value === restrictValues[i] ) {
				status = true;
				i = restrictValues.length;
			}
		}
	}
	else if ( checkType === "checkbox" ) {
		const values = value.split(" "); // verify the input is correct from the checkbox form
		let allok = true;
		for ( let v=0;v<values.length;v++ ) {
			let valueFound = false;
			for ( let i=0;i<restrictValues.length;i++ ) {
				if ( values[v] === restrictValues[i] ) {
					valueFound = true;
					i = restrictValues.length;
				}
			}
			if ( !valueFound ) {
				warning(Lang.invalidInput);
				v = values.length;
				allok = false;
			}
		}
		status = allok;
	}
	else if ( checkType === "text" || checkType === "text_pic" ) {
		console.log("text check"+value+"end");
		if ( value !== null && value !== "" ){
			const valueCompressed = value.replace(/\s+/g,"");
			console.log("text check"+value+"end");
			if ( valueCompressed === " " || valueCompressed === "" ) {
				warning(Lang.inputRequired);
			}
			else {
				if ( restrictValues[0] === "alpha" ) {
					if ( !restrictValues[1] ) {
						restrictValues[1] = 254;
					}
					if ( value.length <= restrictValues[1] ) {
						if ( /^[A-Za-z0-9 ]*$/.test(value) ) {
							status = true;
						}
						else {
							warning(Lang.onlyNumbersLetters);
						}
					}
					else {
						warning(Lang.inputLengthErrorA+restrictValues[1]+Lang.inputLengthErrorB);
					}
				}
				else if ( restrictValues[0] === "number" ) {
					if ( value === numberOnlyInput(value, restrictValues[1], restrictValues[2]) ) {
						if ( !restrictValues[3] ) {
							restrictValues[3] = 0;
						}
						const multipler = 1+"0".repeat(Number(restrictValues[3]));
						value = value*Number(multipler);
						console.log(value);
						if ( Math.abs(value) - Math.floor(value) ) {
							if ( Number(restrictValues[3]) === 0 ) {
								warning(Lang.decimalWarning);
							}
							else {
								warning(Lang.decimalWarn1+restrictValues[3]+Lang.decimalWarn2);
							}
							return false;
						}
						status = true;
					}
				}
				else {
					if ( !restrictValues[1] ) {
						restrictValues[1] = 254;
					}
					if ( value.length <= restrictValues[1] ) {
						status = true;
					}
					else {
						warning(Lang.inputLengthErrorA+restrictValues[1]+Lang.inputLengthErrorB);
					}
				}
			}
		}
		else {
			warning(Lang.inputRequired);
		}
	}
	else if ( checkType === "picture" ) {
		status = true;
	}
	return status;
}

function handleException(value: any, setExceptionText: any) {
	if ( checkInput("text","alpha,240",value) ) {
		setExceptionText(value);
	}
	else if ( value === "" ) {
		setExceptionText("");
	}
}

function handleNote(value: string, setMeterNote: any) {
	if ( checkInput("text","all,250",value) ) {
		setMeterNote(value);
	}
	else if ( value === "" ) {
		setMeterNote("");
	}
}

export {ButtonComponent, Checkbox, checkInput, Radio, handleException, handleNote};

