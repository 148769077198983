import { useState, useEffect } from "react";
import {lang} from "./appLang.js";
import worker from "../images/worker.png";
import {ButtonComponent} from "./FormComponents";
import {Modal} from "./Modal";
import { info, success, warning, error } from "./Notifications";
import { db } from "../services/db";

const Lang = lang();

const StartPage = ({userName, employee_id}:any) => {
	let message = Lang.amMessage;
	const d = new Date();
	if ( d.getHours() > 11 ) {
		message = Lang.pmMessage;
	}
   
	return (<><div className="centre">
		<div className="startPageWelcome centre">
			<div className="startPageWelcomeInner">
				<div className="startPageWelcomeText floatLeft">{userName},<br/>{message}</div>
				<div className="startPageWorker floatRight">
					<img src={worker} title={Lang.workerLabel} alt={Lang.workerLabel}/>
				</div>
			</div>
			
		</div>
	</div>
	<div className="centre">
		<div className="openTasks ">
			<div className="title">
				{Lang.currentTasks}
			</div>
		</div>
	</div>
	<div className="centre">
		<div className="completedTasks">
			<div className="title">
				{Lang.completedTasks}
			</div>
		</div>
	</div>
	</>
	);
};

export {StartPage};