import { success, error, warning } from "../components/Notifications";
import {lang} from "../components/appLang.js";
import { getStaticTable } from "./dbSections";
const Lang = lang();

async function refreshStaticTable(tablename, employee_id, force, noError=true) {
	var last_update = localStorage.getItem(tablename);
	if ( force ) {
		last_update = null;
	}
	const returnValue = await getStaticTable(tablename,employee_id,last_update);
	if ( returnValue === "noUpdate" ) {
		if ( noError ) {
			warning(Lang.tableUpToDate,true);
		}
		return 1;
	}
	else if ( returnValue === "updateerror" ) {
		if ( noError ) {
			error(Lang.updateError,true);
		}
		return 1;
	}
	if ( noError ) {
		success(Lang.successMessage);
	}
	last_update = new Date().toISOString();
	localStorage.setItem(tablename,last_update);
	return 0;
}

export { refreshStaticTable };
