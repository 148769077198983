
interface basicBar {
   currentValue: number;
   label: string;
   maxValue?: number;
}

const BasicProgressBarWithLabel = ({currentValue, label, maxValue=100}:basicBar) => {
	const currentValueString = (Math.trunc(currentValue*100)/100).toString()+"%";
	return (
		<div className="centre">
			<label htmlFor="progress-bar" className="progressLabel">{label}</label>
			<div title={currentValueString} className="inblock">
				<progress 
					id="progress-bar"
					className="progress1"
					value={currentValue} 
					max={maxValue}>{currentValue}%
				</progress>%
			</div>
		</div>
	);};

function progressPercentage (JobStatus:any) {
	const numberTask = JobStatus.length+1;
	let completedTask = 1;
	for ( let i=0;i<JobStatus.length;i++ ) {
		if ( JobStatus[i].status ) {
			completedTask++;
		}
	}
	completedTask = (completedTask / numberTask) * 100;
	return completedTask;
}

export {BasicProgressBarWithLabel, progressPercentage};