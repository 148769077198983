import { useState, useEffect } from "react";
import { Html5Qrcode } from "html5-qrcode";
import {lang} from "./appLang.js";
import { ButtonComponent } from "./FormComponents";
import { warning } from "./Notifications.js";

const Lang = lang();
const FACING_MODE_USER = { facingMode: "user" }; //Front Camera
const FACING_MODE_ENVIRONMENT = { facingMode: "environment" }; //Back Camera

const QRScanner = ({setMeterSerial, setScanState, onRequestClose, shouldSave, saveImage}:any) => {
	const [isScanning, setIsScanning] = useState(false);
	const [qrCodeReader, setQrCodeReader] = useState<any>(null);
	const [videoConstraints, setVideoConstraints] = useState(FACING_MODE_ENVIRONMENT);
	const [cameraFound, setCameraFound] = useState(false);
	const [numberCamera, setNumberCamera] = useState(0);
	const [closeScanner, setCloseScanner] = useState(false);
	const [mediaDevices, setMediaDevies] = useState<any>(null);
	const [mediaStream, setMediaStream] = useState<any>(null);

	useEffect (() => {
		getListOfVideoInputs().then((values) => {
			console.log(values);
			if ( values.length === 1 ) {
				if ( values[0].deviceId === "" ) {
					warning(Lang.noCamera);
					setCameraFound(false);
					setIsScanning(false);
					setNumberCamera(0);
					navigator.mediaDevices.getUserMedia({video: true}).then( mediaStream => {
						setMediaStream(mediaStream);
					}).catch(function(err) {console.log("error occured"+err);});
				}
				else {
					setCameraFound(true);
					setIsScanning(true);
					setNumberCamera(1);
				}
				
			}
			else {
				setCameraFound(true);
				setIsScanning(true);
				setNumberCamera(values.length);
			}
		});
	},[]);

	useEffect (() => {
		if ( mediaDevices !== null ) {
			if ( mediaDevices.length === 1 ) {
				if ( mediaDevices[0].deviceId === "" ) {
					warning(Lang.noCamera);
					setCameraFound(false);
					setNumberCamera(0);
					for ( let i=0;i<10;i++) {
						if ( numberCamera !== 0 ) {
							i = 10;
						}
						else {
							setTimeout(() => {getListOfVideoInputs();},500);
						}
					}
				}
				else {
					setCameraFound(true);
					setIsScanning(true);
					setNumberCamera(1);
				}
			}
			else {
				setNumberCamera(mediaDevices.length);
				setCameraFound(true);
				setIsScanning(true);
			}
		}
		
	},[mediaDevices]);

	useEffect (() => {
		console.log("trying to close scanner");
		if ( !setScanState ) {
			setCloseScanner(true);
		}
	},[setScanState]);

	useEffect (() => {
		if ( closeScanner ) {
			stopScanning();
		}
	},[closeScanner]);
	
	useEffect(() => {
		console.log("in the in scanning useEffect");
		if (isScanning && cameraFound && numberCamera > 0 && !qrCodeReader ) {
			console.log("starting the camera");
			if ( window.innerHeight < window.innerWidth ) {
				const widthValue = window.innerHeight+"px";
				const element = document.getElementById("reader");
				if ( element !== null ) {
					element.style.width = widthValue;
				}
				const element2 = document.getElementById("readerButton");
				if ( element2 !== null ) {
					element2.style.width = window.innerWidth*.9+"px";
				}
				
			}
			const reader = new Html5Qrcode("reader");
			setQrCodeReader(reader);
			reader.start(
				videoConstraints ,
				{
					fps:10,
					qrbox: {width: 200, height: 200},
				},
				(success) => {
					if(success){
						if ( /\s/.test(success) ) {  // check for whitespace, and take the first group
							success = success.split(/(\s)/)[0];
						}
						if ( shouldSave ) {
							captureFrame(saveImage, success);
						}
						setMeterSerial(success);
						setCloseScanner(true);
						setIsScanning(false);
						// success("read code");
					}
				},
				(errorMessage) => {
					if (!errorMessage.includes("QR code parse error")) {
						console.error(`Error during scanning: ${errorMessage}`);
					}
				}
			);
			
		} 
		else if ( !isScanning && qrCodeReader ) {
			console.log("trying to stop camera");
			if ( qrCodeReader.isScanning ) {
				console.log("stopping camera");
				qrCodeReader.stop().then(() => {
					setQrCodeReader(null);
					if ( mediaStream ) {
						console.log(mediaStream);
						const videoTrack = mediaStream.getVideoTracks()[0];
						console.log(videoTrack);
						mediaStream.removeTrack(videoTrack);
					}
					onRequestClose();
				});
			}
			else {
				if ( mediaStream ) {
					console.log(mediaStream);
					const videoTrack = mediaStream.getVideoTracks()[0];
					console.log(videoTrack);
					mediaStream.removeTrack(videoTrack);
				}
				onRequestClose();
			}
		}
		else if ( !isScanning && !setScanState ) {
			if ( mediaStream ) {
				console.log(mediaStream);
				const videoTrack = mediaStream.getVideoTracks()[0];
				console.log(videoTrack);
				mediaStream.removeTrack(videoTrack);
			}
			onRequestClose();
		}
		console.log(qrCodeReader);
	}, [isScanning, cameraFound, numberCamera]);
	
	const startScanning = () => {
		setIsScanning(true);
	};
	const stopScanning = async () => {
		setIsScanning(false);
	};

	const getListOfVideoInputs = async () => {
		const mediaDevices = await navigator.mediaDevices.enumerateDevices();
		setMediaDevies(mediaDevices.filter(device => device.kind === "videoinput"));
		return mediaDevices.filter(device => device.kind === "videoinput");
	};

	const switchCamera = async () => {
		const videoInputList = await getListOfVideoInputs();
		if (videoInputList.length > 1) {
			const currectVideoConstraints = { ...videoConstraints };

			// If the current constraint is the front camera, switch to the back camera.
			if (JSON.stringify(currectVideoConstraints) === JSON.stringify(FACING_MODE_USER)) {
				setVideoConstraints(FACING_MODE_ENVIRONMENT);
			}
			// If the current constraint is the back camera, switch to the front camera.
			if (JSON.stringify(currectVideoConstraints) === JSON.stringify(FACING_MODE_ENVIRONMENT)) {
				setVideoConstraints(FACING_MODE_USER);
			}
			await stopScanning().then(() => {
				startScanning();
			});
		}
		else if ( videoInputList.length === 1 ) {
			warning(Lang.oneCamera);
		} 
		else {
			warning(Lang.noCamera);
		}
	};

	function captureFrame(saveImage:any, serialNumber:string) {
		if ( document.getElementById("qr-canvas") === null ) {
			return;
		}
		if ( document.getElementsByTagName("video") === null ) {
			return;
		}
		const canvas: HTMLCanvasElement = document.getElementById("qr-canvas") as HTMLCanvasElement;
		const video = document.getElementsByTagName("video")[0];
		canvas.width = video.videoWidth;
		canvas.height = video.videoHeight;
		canvas?.getContext("2d")?.drawImage(video, 0, 0, video.videoWidth, video.videoHeight); // for drawing the video element on the canvas
		const image = canvas.toDataURL("image/jpeg");
		saveImage(image, serialNumber);
	}
	
	return (<>{cameraFound ? (<>
		<div id='Scanner' className="blockSection">
			<div id="reader"  className="centre"></div>

		</div>
		<div id="readerButton" className="centre">
			{ numberCamera > 1 ? (<ButtonComponent action={() => {switchCamera();}} buttonText={Lang.switchCamera}/>) : null }
			{ isScanning === false ? (<ButtonComponent action={() => {startScanning();}} buttonText={Lang.startScan}/>) : (<ButtonComponent action={() => {setCloseScanner(true);stopScanning();}} buttonText={Lang.stopScan}/>)}
		</div></>)
		: (<><h3 className="centre">{Lang.noCamera}</h3>
			<div className="centre">{Lang.noCameraMessage}</div>
		</>)}
	</>
	);
};
export {QRScanner};