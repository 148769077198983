import {db} from "../services/db.tsx";
import { getToken } from "./access.js";
import { apiURL } from "../controlVariables.js";

async function setStaticTable (tableName, last_update) {
	if ( last_update === null  || last_update === "" || last_update === undefined ) {
		last_update = new Date();
	}
	try {
		await db.testTable.put({id: 1, updated: last_update});
	}
	catch (error) {
		console.log(error);
		return("updateerror");
	}
}


async function getStaticTable (tableName, last_update) {
	// call for current employee id
	const key = getToken();
	const value = getLoggedInUser();
	if ( key !== "" && value.employee_id !== 0 ) {
		const fetchURL = apiURL+"/static/"+tableName+"?lastUpdate="+last_update+"&employee_id="+value.employee_id+"&userKey="+key;
		console.log(fetchURL);
		let dbData = "";
		try {
			const response = await fetch(fetchURL,{"Content-Type": "mulitpart/form-data"});
			dbData = await response.json();
		}
		catch {
			return "updateerror";
		}
		if ( dbData === "" ) {
			return "noUpdate";
		}
		else if (  dbData.status === "error" ) {
			return "updateerror";
		}
		else if ( dbData.status === "successful" ) {
			return "noUpdate";
		}
		const action = "put";
		if ( tableName === "worklogtasks" ) {
			for (let i = 0; i < dbData.data.length; i++) {
				try {
					await db.worklogtasks[action]({
						id: dbData.data[i].id,
						majortask: dbData.data[i].majortask,
						minortask: dbData.data[i].minortask,
						short_description: dbData.data[i].short_description,
						updated_at: dbData.data[i].updated_at
					});
				}
				catch (error) {
					console.log(error);
					return("updateerror");
				}
			
			}
		}
		else if ( tableName === "inputcontrols" ) {
			for (let i = 0; i < dbData.data.length; i++) {
				try {
					await db.inputcontrols[action]({
						id: dbData.data[i].id,
						form_name: dbData.data[i].form_name,
						field: dbData.data[i].field,
						field_type: dbData.data[i].field_type,
						restriction: dbData.data[i].restriction,
						active: dbData.data[i].active,
						mandatory: dbData.data[i].mandatory,
						position: dbData.data[i].position,
						updated_at: dbData.data[i].updated_at,
						minor_type: dbData.data[i].minor_type,
						sub_type: dbData.data[i].sub_type,
						exception_allowed: dbData.data[i].exception_allowed,
						hold: dbData.data[i].hold
					});
				}
				catch (error) {
					console.log(error);
					return("updateerror");
				}
			}
			
		}	
	}
	return ;
}

async function updateAPI_systemControls (updated_at) {
	if ( updated_at === undefined || updated_at === null ) {
		updated_at = "1970-01-01T00:00:00";
	}
	const userKey = await getToken(); 
	const pushURL = apiURL+"/static/systemcontrols?lastUpdate="+updated_at+"&userKey="+userKey+"&employee_id=0";
	try {
		await db.systemControls.where("name")
			.noneOf("current_user","token")
			.toArray()
			.then (async (localValue) => {
				for (let i = 0; i < localValue.length; i++) {
					const storedDate = new Date(localValue[i].update).getTime();
					const givenDate = new Date(updated_at).getTime();
					if ( storedDate > givenDate ) {
						const response = await fetch(pushURL,{
							method: "POST",
							headers: {
								"Content-Type": "application/json",
							},
							body: JSON.stringify(localValue[i]),
						});
						const dbData = await response.json();
						console.log(dbData);
						if ( dbData.status !== "ok" ) {
							i = localValue.length;
							console.log("error setting value");
							return "not ok";
						}
					}
					
				}
			});
	}
	catch (error) {
		console.log(error);
		return "not ok";
	}
	
	return "ok";
	
}

async function saveGeoLocations () {
	const updated_at = "1970-01-01T00:00:00";
	const userKey = await getToken();
	const pushURL = apiURL+"/static/geo_locations?lastUpdate="+updated_at+"&userKey="+userKey+"&employee_id=0";
	try {
		await db.geoLocation.orderBy("timestamp").toArray()
			.then (async (results) => {
				if ( results.length > 0 ) {
					for ( let i=0;i<results.length;i++ ) {
						let savedWorklogId = 0;
						let apiWorklogId = 0;
						if ( savedWorklogId !== results[i].worklog_id && results[i].worklog_id !== 0 ) {
							savedWorklogId = results[i].worklog_id;
							apiWorklogId = 0;
							await getWorklogAPIID(Number(results[i].worklog_id))
								.then((value) => {
									console.log(value);
									apiWorklogId = value;
								});
						}
						else if ( results[i].worklog_id === 0 ) {
							await db.geoLocation.where("id").equals(results[i].id).delete();
						}
						if ( apiWorklogId !== 0 ) {
							const payload = {
								lat: results[i].lat,
								lon: results[i].lon,
								alt: results[i].alt,
								timeStamp: results[i].timestamp,
								worklog_id: apiWorklogId
							};
							console.log(payload);
							const response = await fetch(pushURL,{
								method: "POST",
								headers: {
									"Content-Type": "application/json",
								},
								body: JSON.stringify(payload),
							});
							const dbData = await response.json();
							if ( dbData.status === "ok" ) {
								await db.geoLocation.where("id").equals(results[i].id).delete();
							}
							else {
								console.log("Error saving the Geo Locations to API");
								console.log(dbData);
							}
						}

					} 
				}
			});
	}
	catch (error) {
		console.log("Error Saving Geo Locations to API"+error);
	}
	return 0;
}

async function getWorklogAPIID (worklog_id) {
	let api_worklog_id = 0;
	try {
		await db.workLog.where("id").equals(Number(worklog_id)).toArray()
			.then(async (result) => {
				if ( result.length === 1 ) {
					if ( result[0].api_worklog_id !== 0 ) {
						api_worklog_id = Number(result[0].api_worklog_id);
					}
					else {
						const userKey = await getToken(); 
						const pushURL = apiURL+"/static/worklog?lastUpdate="+result[0].updated_at+"&userKey="+userKey+"&employee_id=0";
						const response = await fetch(pushURL,{
							method: "POST",
							headers: {
								"Content-Type": "application/json",
							},
							body: JSON.stringify(result[0]),
						});
						const dbData = await response.json();
						if ( dbData.status === "ok" ) {
							await db.workLog.update(Number(result[0].id),{"api_worklog_id": dbData.id});
							api_worklog_id = dbData.id;
						}
						else {
							console.log(dbData);
							return 0;
						}
					}
				}
				else {
					return 0;
				}
			});
		
	}
	catch (error) {
		console.log("Issue getting the worklog API id ");
		console.log(error);
		return 0;
	}
	return api_worklog_id;
}

async function updateWorklog (updated_at) {
	if ( updated_at === undefined || updated_at === null ) {
		updated_at = "1970-01-01T00:00:00";
	}
	const userKey = await getToken(); 
	const pushURL = apiURL+"/static/worklog?lastUpdate="+updated_at+"&userKey="+userKey+"&employee_id=0";
	try {
		await db.workLog.toArray()
			.then (async (localValue) => {
				for (let i = 0; i < localValue.length; i++) {
					const storedDate = new Date(localValue[i].updated_at).getTime();
					const givenDate = new Date(updated_at).getTime();
					if ( storedDate > givenDate && localValue[i].api_worklog_id !== 0 ) {
						const response = await fetch(pushURL,{
							method: "POST",
							headers: {
								"Content-Type": "application/json",
							},
							body: JSON.stringify(localValue[i]),
						});
						const dbData = await response.json();
						console.log(dbData);
						if ( dbData.status !== "ok" ) {
							i = localValue.length;
							console.log("error setting value");
							return "not ok";
						}
					}
					
				}
			});
	}
	catch (error) {
		console.log(error);
		return "not ok";
	}
	return "ok";
}

async function updateMeters (updated_at) {
	if ( updated_at === undefined || updated_at === null ) {
		updated_at = "1970-01-01T00:00:00";
	}
	const userKey = await getToken(); 
	const pushURL = apiURL+"/static/meters?lastUpdate="+updated_at+"&userKey="+userKey+"&employee_id=0";
	try {
		await db.meterDetails.toArray()
			.then (async (localValue) => {
				for (let i = 0; i < localValue.length; i++) {
					const storedDate = new Date(localValue[i].updated_at).getTime();
					const givenDate = new Date(updated_at).getTime();
					console.log(localValue[i]);
					if ( storedDate > givenDate ) {
						const response = await fetch(pushURL,{
							method: "POST",
							headers: {
								"Content-Type": "application/json",
							},
							body: JSON.stringify(localValue[i]),
						});
						const dbData = await response.json();
						console.log(dbData);
						if ( dbData.status === "ok" ) {
							if ( localValue[i].api_meter_id === 0 ) {
								await db.meterDetails.update(Number(localValue[i].meter_id),{"api_meter_id": Number(dbData.id)});
							}
						}
						else {
							console.log(dbData);
						}
					}
				}
			});
	}
	catch (error) {
		console.log(error);
		return "not ok";
	}
	return "ok";
}

async function getLoggedInUser () {
	let value = {value: "", employee_id: 0};
	try {
		await db.systemControls.where("name").equalsIgnoreCase("current_user").toArray().then((values) => {
			if ( values ) {
				if ( values[0] !== undefined) {
					value = values[0];
				}
			}
		});
	}
	catch (error) {
		console.log(error);
	}
	return value;
}

export { getStaticTable, setStaticTable, updateAPI_systemControls, saveGeoLocations,
	updateWorklog, updateMeters };