import {lang} from "../components/appLang.js";
import loginImage from "../images/loginImage.png";
import loginName from "../images/loginName.png";
import loginLock from "../images/lock.png";
import loginEye from "../images/eye.png";
import { useState, useEffect } from "react";
import { warning } from "../components/Notifications";
import { checkInput } from "./FormComponents";
import { userLength, passwordLength} from "../controlVariables.js";
import { db } from "../services/db";
import { addSystemControls, dbClean } from "./dbSections";
import { getLoggedInUser, setToken } from "../services/access.js";

const Lang = lang();

const LoginPage = ({setLoginState, setOpenId, setEmployee_id, onlineStatus, setUserName}:any) => {
	const [showPassword, setShowPassword] = useState(false);
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [loginErrMessage, setLoginErrMessage] = useState("");
	const [currentUserid, setCurrentUserid] = useState(0);

	useEffect (() => {
		getLoggedInUser().then((values) => {
			if (values.value === "" ) {
				setUsername("Demo User");
			}
			else {
				setUsername(values.value);
			}	
			setCurrentUserid(values.employee_id);
		});
	},[]);

	const checkKeyPress = (e:any) => {
		const { key, keyCode } = e;
		if (keyCode === 13) {
			handleLogin();
		}
	};

	function handleUsername (value: string) {
		if ( value.length <= userLength ) {
			setUsername(value);
			setLoginErrMessage("");
		} 
		else if ( value === "" ) {
			setUsername("");
			setLoginErrMessage("");
		}
		else {
			setLoginErrMessage(Lang.loginError);
		}
	}

	function handlePassword (value: string) {
		if ( value.length <= passwordLength ) {
			setPassword(value);
			setLoginErrMessage("");
		}
		else if ( value === "" ) {
			setPassword("");
			setLoginErrMessage("");
		}
		else {
			setLoginErrMessage(Lang.loginError);
		}
	}

	async function handleLogin () {
		let employee_id =1;
		if ( username.length === 0 ) {
			setLoginErrMessage(Lang.loginError);
		}
		else if ( password.length < 8 ) {
			setLoginErrMessage(Lang.passwordLengthError);
		}
		else {  // login passed basic checks
			// check for valid username
			// check for valid password
			const token = "isasupersecurekey"; // set when validating username/password
			if ( username === "test2" ) {
				employee_id = 2;
			}
			else if ( username === "test3" ) {
				employee_id = 3;
			}
			else if ( username === "test4" ) {
				employee_id = 4;
			}
			if ( employee_id !== currentUserid ) {
				await dbClean(employee_id, token).then(async () => {
					setCurrentUserid(0);
					await addSystemControls("current_user", username, employee_id, false);
					await setToken(employee_id, token);
					setLoginState(true);
					localStorage.removeItem("lastTaskDetails");
				});	
				
			}
			else {
				setLoggedInUser(employee_id);
				await setToken(employee_id, token);
				setLoginState(true);
			}
			setOpenId();
			setEmployee_id(employee_id);
			setUserName(username);
			localStorage.setItem("loggedIn","true");
		}
	}

	async function setLoggedInUser (employeeID: number) {
		const updated = new Date();
		console.log("saving user: "+username);
		try {
			console.log("trying to update");
			await db.systemControls.where({name: "current_user", employee_id: currentUserid})
				.modify({
					value: username,
					update: updated
				});
		}
		catch (error) {
			console.log(error);
		}
	}

	return (<>
		<div className="blockSection">
			<div className="loginPage centre">
				<div className="loginBackground centre">
					<img className="loginPage_img" src={loginImage} alt={Lang.companyName}/>
				</div>
				<div className="loginBottom blockSection">
					<div className="loginTitle title centre">
						{Lang.welcomeLogin}
					</div>
					<div className="loginError">{loginErrMessage}</div>
					<div className="loginSection">
						<div className="blockSection">
							<div className="loginName">
								<img className="loginName_img" src={loginName}/>
								<input 
									type="text"
									onChange={(e) => {handleUsername(e.target.value);}}
									onKeyDown={checkKeyPress}
									value={username}
									placeholder={Lang.userName}
								/>
							</div>
						</div>
						<div className="blockSection">
							<div className="loginPassword">
								<img className="loginPassword_img" src={loginLock}/>
								<img className="loginPassEye_img pointer" 
									src={loginEye}
									onClick={() => {setShowPassword((prev) => !prev);}}/>
								<input 
									id="password"
									type={showPassword ? "text" : "password"}
									onChange={(e) => {handlePassword(e.target.value);}}
									value={password}
									onKeyDown={checkKeyPress}
									placeholder={Lang.password}
								/>
							</div>
						</div>
						{ onlineStatus ? (<div 
							className="loginButton centre pointer"
							onClick={() => {handleLogin();}}>
							{Lang.loginButtonText}
						</div>) : (<div className="login_nointernet">{Lang.noLoginWoConnection}</div>)}
						<div className="blockSection"></div>
					</div>
				</div>
			</div>
		</div>
		<div className="centre">
			<div className="loginNotes blockSection">
			Note: this is a MVP demo. It is for evaluation purposes and no data is being collected. If you encounter
				any issues, please email <a href="mailto:mike.okrongli@sagacitysoft.ca">mike.okrongli@sagacitysoft.ca</a>
			</div>
		</div>
	</>);
};

export { LoginPage };