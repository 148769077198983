import { lang } from "./appLang.js";
import {db} from "../services/db.tsx";
import { success, warning } from "./Notifications.js";
import { refreshStaticTable } from "./staticRetrieval.js";
import { getToken } from "../services/access.js";
import { apiURL } from "../controlVariables.js";

const Lang = lang();


async function getStaticTable (tableName, employee_id, last_update, tokenValue) {
	if ( tokenValue === null || tokenValue == undefined ) {
		tokenValue = await getToken();  // get the key as required
	}
	if ( last_update === null  || last_update === "" || last_update == undefined ) {
		last_update = "1970-01-01T00:00:00";
	}
	const fetchURL = apiURL+"/static/"+tableName+"?lastUpdate="+last_update+"&employee_id="+employee_id+"&userKey="+tokenValue;
	console.log(fetchURL);
	let dbData = "";
	try {
		const response = await fetch(fetchURL,{"Content-Type": "mulitpart/form-data"});
		dbData = await response.json();
	}
	catch {
		return "updateerror1";
	}
	if ( dbData === "" ) {
		return "noUpdate";
	}
	else if (  dbData.status === "error" ) {
		return "updateerror";
	}
	else if ( dbData.status === "successful" ) {
		return "noUpdate";
	}
	const action = "put";
	if ( tableName === "worklogtasks" ) {
		for (let i = 0; i < dbData.data.length; i++) {
			try {
				await db.worklogtasks[action]({
					id: dbData.data[i].id,
					majortask: dbData.data[i].majortask,
					minortask: dbData.data[i].minortask,
					short_description: dbData.data[i].short_description,
					updated_at: dbData.data[i].updated_at
				});
			}
			catch (error) {
				console.log(error);
			}
			
		}
	}
	else if ( tableName === "inputcontrols" ) {
		console.log(dbData.data);
		for (let i = 0; i < dbData.data.length; i++) {
			try {
				await db.inputcontrols[action]({
					id: dbData.data[i].id,
					form_name: dbData.data[i].form_name,
					field: dbData.data[i].field,
					field_type: dbData.data[i].field_type,
					restriction: dbData.data[i].restriction,
					active: dbData.data[i].active,
					mandatory: dbData.data[i].mandatory,
					position: dbData.data[i].position,
					updated_at: dbData.data[i].updated_at,
					minor_type: dbData.data[i].minor_type,
					sub_type: dbData.data[i].sub_type,
					exception_allowed: dbData.data[i].exception_allowed,
					hold: dbData.data[i].hold
				});
			}
			catch (error) {
				console.log(error);
			}
		}
			
	}
	else if ( tableName === "properties" ) {
		for (let i = 0; i < dbData.data.length; i++) {
			try {
				await db.properties[action]({
					id: dbData.data[i].id,
					client_id: dbData.data[i].client_id,
					street1: dbData.data[i].street1,
					city: dbData.data[i].city,
					updated_at: dbData.data[i].updated_at
				});
			}
			catch (error) {
				console.log(error);
			}
		}
	}
	else if ( tableName === "property_details" ) {
		for (let i = 0; i < dbData.data.length; i++) {
			try {
				await db.property_details[action]({
					id: dbData.data[i].id,
					client_id: dbData.data[i].client_id,
					updated_at: dbData.data[i].updated_at,
					meter_electric: dbData.data[i].meter_electric,
					meter_gas: dbData.data[i].meter_gas,
					meter_water: dbData.data[i].meter_water,
					meter_water_hot: dbData.data[i].meter_water_hot,
					meter_btu: dbData.data[i].meter_btu,
					pipe_system: dbData.data[i].pipe_system
				});
			}
			catch (error) {
				console.log(error);
			}
		}
	}
	else if ( tableName === "clients" ) {
		for (let i = 0; i < dbData.data.length; i++) {
			try {
				await db.clients[action]({
					client_id: dbData.data[i].client_id,
					name: dbData.data[i].name,
					updated_at: dbData.data[i].updated_at
				});
			}
			catch (error) {
				console.log(error);
			}
		}
	}
	else if ( tableName === "suites" ) {
		for (let i = 0; i < dbData.data.length; i++) {
			try {
				await db.suites[action]({
					suite_key: dbData.data[i].suite_key,
					client_id: dbData.data[i].client_id,
					location_id: dbData.data[i].location_id,
					id: dbData.data[i].id,
					suite: dbData.data[i].suite,
					note: dbData.data[i].note,
					updated_at: dbData.data[i].updated_at
				});
			}
			catch (error) {
				console.log(error);
			}
		}
	}
	else if ( tableName === "systemcontrols" ) {
		for (let i = 0; i < dbData.data.length; i++) {
			try {
				await db.systemControls[action]({
					name: dbData.data[i].control_name,
					employee_id: dbData.data[i].employee_id,
					value: dbData.data[i].value,
					consent: dbData.data[i].consent,
					update: dbData.data[i].updated_at
				});
			}
			catch (error) {
				console.log(error);
			}
		}
	}
	
	return ;

}

async function dbClean(employee_id, tokenValue) {
	await db.properties.clear();
	await db.clients.clear();
	await db.systemControls.clear();
	await getStaticTable ("properties", employee_id, null, tokenValue);
	await getStaticTable ("clients", employee_id, null, tokenValue);
	await getStaticTable ("systemcontrols", employee_id, null, tokenValue);
}

async function dbRemove(dbName) {
	// check to see if outstanding transactions to the API are outstanding
	var req = indexedDB.deleteDatabase(dbName);
	req.onsuccess = function () {
		console.log(Lang.dbDelete);
		window.location.reload();
	};
	req.onerror = function () {
		console.log(Lang.dbDeleteError);
		return;
	};
	req.onblocked = function () {
		console.log(Lang.dbDeleteBlock);
		window.location.reload();
	};
	const last_update = "1970-01-01T00:00:00";
	localStorage.setItem("worklogtasks",last_update);
	localStorage.setItem("inputcontrols",last_update);
	localStorage.setItem("suites",last_update);
	localStorage.setItem("currentWorklog",last_update);
	localStorage.setItem("clients",last_update);
	localStorage.setItem("properties",last_update);
	localStorage.setItem("property_details",last_update);
}

async function controlValue (installType) {
	const results = await db.inputcontrols.where("form_name").equalsIgnoreCase(installType).sortBy("position");
	return results;
} 

async function initializeDB (employee_id) {
	const last_update = "1970-01-01T00:00:00";
	success(Lang.checkUpdates);
	let returnValue = 0;
	returnValue = refreshStaticTable("worklogtasks",employee_id, true, false);
	if ( returnValue > 0 ) {
		warning(Lang.errorStaticWorktask);
		localStorage.setItem("worklogtasks",last_update);
	}
	returnValue = refreshStaticTable("inputcontrols",employee_id, true, false);
	if ( returnValue > 0 ) {
		warning(Lang.errorStaticInput);
		localStorage.setItem("inputcontrols",last_update);
	}
	returnValue = refreshStaticTable("suites",employee_id, true, false);
	if ( returnValue > 0 ) {
		warning(Lang.errorStaticSuites);
		localStorage.setItem("suites",last_update);
	}
	returnValue = refreshStaticTable("clients",employee_id, true, false);
	if ( returnValue > 0 ) {
		warning(Lang.errorStaticClients);
		localStorage.setItem("clients",last_update);
	}
	returnValue = refreshStaticTable("properties",employee_id, true, false);
	if ( returnValue > 0 ) {
		warning(Lang.errorStaticProperties);
		localStorage.setItem("properties",last_update);
	}
	returnValue = refreshStaticTable("property_details",employee_id, true, false);
	if ( returnValue > 0 ) {
		warning(Lang.errorStaticProperties);
		localStorage.setItem("property_details",employee_id, last_update);
	}
	success(Lang.finishCheckUpdates);
	return;
}

function recordException (client_id, property_id, suite, employee_id, meter_id, exType, oldValue, newValue, note) {
	const updated_at = new Date().toISOString();
	db.exceptionDetails.add({
		client_id: client_id,
		property_id: property_id,
		suite: suite,
		employee_id: employee_id,
		meter_id: meter_id,
		exceptionType: exType,
		oldValue: oldValue,
		newValue: newValue,
		note: note,
		updated_at: updated_at
	});
	return;
}

function addWorklogDetails (worklog_id, meter_id, setWorklogDetailsID) {
	const started_at = new Date();
	db.worklogDetails.add({
		worklog_id: worklog_id,
		meter_id: meter_id,
		started_at: started_at
	}).then((id) => {
		setWorklogDetailsID(id);
	});
	return;
}

function addWorklogAudit (worklog_id, field, value) {
	const entered_at = new Date();
	db.worklogAudit.add({
		worklogDetails_id: worklog_id,
		field: field,
		value: value,
		entered_at: entered_at
	});
	return;
}

async function addSystemControls(name, value, employee_id, consent) {
	const update = new Date();
	db.systemControls.add({
		name: name,
		value: value,
		employee_id: employee_id,
		consent: consent,
		update: update
	});
}

export {dbRemove, dbClean, getStaticTable, controlValue, initializeDB, recordException, addWorklogDetails, addWorklogAudit, addSystemControls};
