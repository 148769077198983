import { enlang } from "./langEnglish.js";
import { frlang } from "./langFrench.js";

const english = enlang();
const french = enlang();


export const lang = (input_lang) => {
    
	if ( input_lang === "" ){
		input_lang = "en";
	}
	
	if ( typeof window !== "undefined" ) {
		if (localStorage.getItem("appLang") ) {
			input_lang = localStorage.getItem("appLang");
		}
		else {
			localStorage.setItem("appLang",input_lang);
		}
	}

	if (input_lang === "fr" ) {
		return french;
	}

	return english;
};

export default lang;
