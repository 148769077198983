import { useState } from "react";
import { db } from "../services/db";
import { ButtonComponent, Checkbox } from "./FormComponents";
import {lang} from "../components/appLang.js";
import { addSystemControls } from "./dbSections";

const Lang = lang();

const GeneralUsage = ({onRequestClose, logout, approved, employee_id}:any) => {
	const [disclaimerCheck, setDisclaimerCheck] = useState(false);
	const [nagAgree, setNagAgree] = useState(false);
   
	function handleSubmit() {
		if ( disclaimerCheck ) {
			addSystemControls("generalDisclaimer", "", employee_id, true);
			approved();
			onRequestClose();
		}
		else {
			if ( !nagAgree ) {
				if ( !confirm(Lang.confirmMessage) ) {
					return;
				}
			}
			logout();
			onRequestClose();
		}
	}
   
	return (<>
		<h3>Disclaimer:</h3>
		<div>This app collects data and you must agree to these terms:</div>
		<ul>
			<li>Geo-location data is collected when you are logged into the app</li>
			<li>The device camera is required for some functions</li>
			<li>Camera images can be taken and stored when active</li>
		</ul>
		<Checkbox label={Lang.termsAgreeStatement} formUpdate={() => {setDisclaimerCheck(!disclaimerCheck);}}/>
		<ButtonComponent action={() => {handleSubmit();}} buttonText={Lang.agree} />
		<ButtonComponent action={() => {logout();onRequestClose();}} buttonText={Lang.decline} />
	</>
	);

};

export {GeneralUsage};
