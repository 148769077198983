import React from "react";
import {useEffect, useState } from "react";
import Select from "react-select";
import {lang} from "./appLang.js";
import {db} from "../services/db";
import { controlValue, recordException, addWorklogDetails, addWorklogAudit } from "./dbSections";
import { success, warning } from "./Notifications.js";
import { ButtonComponent, Radio, Checkbox, checkInput, handleException, handleNote } from "./FormComponents";
import {Modal, ModalFull} from "./Modal";
import { PropertyDetails, PropertyDetailsFound, showPropertyDetails } from "./Properties";
import { getSuiteNote } from "./suite";
import { newMeter, getSuiteMeters, getSuiteMeter, MeterOverride, SerialNumber} from "./meters";
import { GetImage } from "../services/images";
import { getJobStatus, updateJobStatus } from "../services/jobs";
import { checkForLang } from "../services/tools.js";
import {QRScanner} from "./Scanner";
import { saveImage } from "../services/images";
import camera from "../icons/camera.png";
import { BasicProgressBarWithLabel, progressPercentage } from "./progress";
import { Camera } from "./camera";
const Lang = lang();

const Installation = ({taskResult, loginState}:any) => {
	const [jobTasks, setJobTasks] = useState([]);
	const [jobStatus, setJobStatus] = useState<any>([]);
	const [worklogDetailsID, setWorklogDetailsID] = useState(0);
	const [shouldShow, setShouldShow] = useState(false);
	const [modalValue, setModalValue] = useState<any>();
	const [shouldShowFull, setShouldShowFull] = useState(false);
	const [propertyDetails, setPropertyDetails] = useState<any>();
	const [shouldShowPropDetails, setShouldShowPropDetails] = useState(false);
	const [propertyAddress, setPropertyAddress] = useState("");
	const [shouldShowPropOverride, setShouldShowPropOverride] = useState(false);
	const [suiteMeterSelect, setSuiteMeterSelect] = useState([]);
	const [suiteMeters, setSuiteMeters] = useState([]);
	const [shouldShowSuiteNote, setShouldShowSuiteNote] = useState(false);
	const [suiteNote, setSuiteNote] = useState("");
	const [currentSuiteNote, setCurrentSuiteNote] = useState("");
	const [showSerialNumberInput, setShowSerialNumberInput] = useState(false);
	const [meterDetails, setMeterDetails] = useState<any>([{"serial_number": "", "meter_id": 0, "note": "", "details": "","device_type": ""}]);
	const [shouldShowNote, setShouldShowNote] = useState(false);
	const [meterNote, setMeterNote]  = useState("");
	const [showDefectiveModal, setShowDefectModal] = useState(false);
	const [defectiveNote, setDefectiveNote] = useState("");
	const [storedImage, setStoredImage] = useState();
	const [value, setValue] = useState("");
	const [radioModal, setRadioModal] = useState(false);
	const [textModal, setTextModal] = useState(false);
	const [checkboxModal, setCheckboxModal] = useState(false);
	const [photoModal, setPhotoModal] = useState(false);
	const [skipModal, setSkipModal] = useState(false);
	const [cardValue, setCardValue] = useState<any>();
	const [imageDesc, setImageDesc] = useState<any>();
	const [currentProgress, setCurrentProgress] = useState(0);
	const [anchorNumber, setAnchorNumber] = useState(0);
	const [exceptionText, setExceptionText] = useState("");

	let deviceNoteDescription = Lang.deviceNoteDescription;
	let locationNoteTitle = Lang.locationNoteTitle;
	let deviceNoteTitle = Lang.deviceNoteTitle;
	let devicenoteplaceholder = Lang.devicenoteplaceholder;
	let locationnoteplaceholder = Lang.locationnoteplaceholder;

	if ( taskResult.minorTask === "SuiteInstall" ) {
		deviceNoteDescription = Lang.meterNoteDescription;
		locationNoteTitle = Lang.suiteNoteTitle;
		deviceNoteTitle = Lang.meterNoteTitle;
		devicenoteplaceholder = Lang.meternoteplaceholder;
		locationnoteplaceholder = Lang.suitenoteplaceholder;
	}

	useEffect(() => {
		// reserve for once run components
	},[]);

	useEffect(() => {
		controlValue(taskResult.majorTask).then((result: any) => {
			setJobTasks(result);
		});
		if ( Number(taskResult.openId) !== 0 && loginState ) {
			setMeterDetails([{"serial_number": "", "meter_id": 0, "note": "", "details": "","device_type": ""}]);
			setJobStatus([]);
			setSuiteMeterSelect([]);
			PropertyDetailsFound(taskResult).then((property_details): any => {
				if ( property_details && "id" in property_details ) {
					setPropertyDetails(property_details);
				}
				else {
					setPropertyDetails(property_details);
					setShouldShowPropDetails(true);
				}
			});
			getSuiteNote(taskResult.client_id, taskResult.location, taskResult.suite).then((note:string) => {
				if ( note !== undefined && note !== null ) {
					setSuiteNote(note);
					setCurrentSuiteNote(note);
				}
				
			});
			if ( taskResult.minorTask === "SuiteInstall" ) {
				deviceNoteDescription = Lang.meterNoteDescription;
				locationNoteTitle = Lang.suiteNoteTitle;
			}
		}
		console.log("taskResult useEffect");
	},[taskResult,loginState]);

	useEffect(() => {
		if ( propertyDetails ) {
			setPropertyAddress(propertyDetails.street+", "+propertyDetails.city);
			if ( "id" in propertyDetails === false ) {
				setShouldShowPropDetails(true);
			}
			if ( propertyDetails.meter_btu === false && propertyDetails.meter_electric === false && propertyDetails.meter_gas === false && propertyDetails.meter_water === false && propertyDetails.meter_water_hot === false ) {
				setShouldShowPropDetails(true);
				warning(Lang.meterManditory);
			}
		}
		console.log("property details useEffect");
	},[propertyDetails]);

	useEffect(() => {
		if ( !shouldShow ) {
			setModalValue("");
			setRadioModal(false);
			setTextModal(false);
			setCheckboxModal(false);
			setValue("");
			setExceptionText("");
			setPhotoModal(false);
			setSkipModal(false);
		}
	},[shouldShow]);

	useEffect (() => {
		if ( !shouldShowFull ) {
			setPhotoModal(false);
			setImageDesc("");
			setExceptionText("");
		}
	},[shouldShowFull]);

	useEffect(() => {
		getSuiteMeters(propertyDetails, taskResult.client_id, taskResult.location, taskResult.suite).then((suiteMetersFound:any) => {
			setSuiteMeters(suiteMetersFound);
		});
		if ( suiteMeterSelect ) {
			getSuiteMeter(suiteMeterSelect).then((value) => {
				if ("value" in suiteMeterSelect) {
					if ( value.meter_id !== 0 && value.meter_id !== meterDetails.meter_id ) {
						addWorklogDetails(taskResult.openId,value.meter_id, setWorklogDetailsID);
					}
					getJobStatus(taskResult, jobTasks,value,value.device_type,setJobStatus);
				}
				setMeterDetails(value);
				if ( value.note !== undefined ) {
					setMeterNote(value.note);
				}
			});
		}
		console.log("useEffect meter select");
	},[propertyDetails, suiteMeterSelect]);

	useEffect(() => {
		GetImage (taskResult.client_id, taskResult.location, taskResult.suite, meterDetails.serial_number, meterDetails.meter_id, taskResult.majorTaskNumber, "Meter Scan", setStoredImage);
	},[meterDetails]);

	useEffect (() => {
		setCurrentProgress(progressPercentage(jobStatus));
	},[jobStatus]);
	
	useEffect (() => {
		console.log("Anchor",anchorNumber);
		const element = document.getElementById("stepAnchor"+(anchorNumber+1));
		if ( element ) {
			element.scrollIntoView({behavior: "smooth"});
		}
	},[anchorNumber]);
 
	function updateJobItem(jobPart:any,value:string,exception:string,bypass:boolean) {
		// if ( bypass ) {
		// 	value = Lang.bypassLabel + value;
		// }
		if ( jobPart.field_type === "picture" && !bypass) {
			setShouldShowFull(!updateJobStatus(taskResult, meterDetails, jobPart.field, value, exception, jobStatus, setJobStatus, bypass));
		}
		else {
			setShouldShow(!updateJobStatus(taskResult, meterDetails, jobPart.field, value, exception, jobStatus, setJobStatus, bypass));
		}
		addWorklogAudit(worklogDetailsID, jobPart.field, value);
	}

	async function handleMeter(event: any) {
		const text:string = event.value.toString();
		if ( text.includes("-new") ) {
			const device_type = event.value.split("-");
			await newMeter(taskResult, propertyDetails, device_type[0], "").then((value) => {
				setSuiteMeterSelect(value);
			});
			
		}
		else {
			if ( !("value" in suiteMeterSelect) || event.value !== suiteMeterSelect.value ) {
				setSuiteMeterSelect(event);
			}
		}
		setAnchorNumber(0);
	}

	function viewPropertyDetails () {
		const details = showPropertyDetails(propertyDetails);
		return(<>{details}
			<ButtonComponent action={() => {setShouldShow(false);setShouldShowPropDetails(true);}} buttonText={Lang.changePropDetails}/>
		</>);
	}

	function saveTheImage (image:Blob, serial_number:string, picType:string) {
		saveImage(taskResult.client_id, taskResult.location, taskResult.employee_id, taskResult.suite, picType, meterDetails.meter_id, taskResult.majorTaskNumber, serial_number, image);
	}

	const UpdateRadioModal = ({jobParts, value, setValue, exceptionText, setExceptionText}:any) => {
		const exceptionFound = jobParts.value;
		const radioOutput = [];
		const restrictValues = jobParts.restriction.split(",");
		const restrictScreen = jobParts.screenRestriction.split(",");

		const [exception, setException] = useState(exceptionText);
		

		const checkKeyPress = (e:any) => {
			const { key, keyCode } = e;
			if (keyCode === 13) {
				updateJobItem(jobParts,value,exception,false);
			}
		};
		for ( let i=0;i<restrictValues.length;i++ ) {
			radioOutput.push({
				id: restrictValues[i],
				field: jobParts.field,
				screenName: restrictScreen[i]
			});
		}
		return (<><div className="title">{jobParts.screenName}</div>{radioOutput.map((parts)=> (
			<div key={parts.id} onClick={() => {setExceptionText(exception);}}>
				<Radio radioName={parts.id} setValue={setValue} value={value} screenName={parts.screenName}/>
			</div>
		))}
		{ exceptionFound !== "" ? (<div>
			<div className="blockSection">
				<div className="blockSection">
					{Lang.reasonPrefix} {jobParts.screenName}
				</div>
				
				<input
					name="whyNewValue"
					placeholder={Lang.enterReason}
					value={exception}
					onChange={(e) => {handleException(e.target.value,setException);}}
					onKeyDown={checkKeyPress}
					autoFocus
				/>
			</div>
		</div>) : null}
		<ButtonComponent action={() => {updateJobItem(jobParts,value,exception,false);}} buttonText={Lang.add+" "+jobParts.screenName}/></>);
	}; 

	const UpdateTextModal = ({jobParts, value}:any) => {
		const [textValue, setTextValue] = useState(value);
		const [exception, setException] = useState("");
		const [shouldShowScanner, setShouldShowScanner] = useState(false);
		const [scannerState, setScannerState] = useState(false);
		const [numberpad, setNumberPad] = useState<any>("text");

		useEffect (() => {
			const restrictValues = jobParts.restriction.split(",");
			if ( restrictValues[0] === "number" ) {
				setNumberPad("numeric");
			}
		},[jobParts]);
		

		useEffect(() =>{
			// need to rerender
		},[scannerState]);

		function handleText(value:string) {
			if ( checkInput("text",jobParts.restriction,value) ) {
				setTextValue(value);
			}
			else if ( value === "" ) {
				setTextValue("");
			}
		}

		const checkKeyPress = (e:any) => {
			const { key, keyCode } = e;
			if (keyCode === 13) {
				
				updateJobItem(jobParts,textValue,exception,false);
			}
		};
		return (<><div className="title">{jobParts.screenName}</div>
			<div>
				<div className="blockSection">
					<input
						name="newText"
						placeholder={jobParts.screenName}
						autoFocus
						value={textValue}
						onChange={(e) => {handleText(e.target.value);}}
						onKeyDown={checkKeyPress}
						inputMode={numberpad}
					/>
					{ jobParts.field_type === "text_pic" ? (<div className="inblock pointer smallImage" onClick={() => 
					{setScannerState(true);setShouldShowScanner(true);}}><img src={camera} alt={Lang.cameraIcon}/>
					</div>) : null}
				</div>
				{ value !== undefined &&  value !== "" ? (
					<div className="blockSection">
						<div className="blockSection">
							{Lang.reasonPrefix} {jobParts.screenName}
						</div>
						<input
							name="whyNewValue"
							placeholder={Lang.enterReason}
							value={exception}
							onChange={(e) => {handleException(e.target.value,setException);}}
							onKeyDown={checkKeyPress}
						/>
					</div>
				) : null}
				<ButtonComponent action={() => {updateJobItem(jobParts,textValue,exception,false);}} buttonText={jobParts.screenName}/>
			</div>
			<ModalFull shouldShow={shouldShowScanner} onRequestClose={() => {setScannerState(false);}}>
				<QRScanner setMeterSerial={(value:string) => {setTextValue(value);}} setScanState={scannerState} onRequestClose={() => {setScannerState(false);setShouldShowScanner(false);}} shouldSave={true} saveImage={(image:Blob,serial_number:string) => {saveTheImage(image,serial_number,jobParts.field);}}/>
			</ModalFull>
		</>);
	};

	const UpdateCheckboxModal = ({jobParts, value, setValue, exceptionText, setExceptionText}:any) => {
		const exceptionFound = jobParts.value;
		const [exception, setException] = useState(exceptionText);
		const [checkboxValues, setCheckboxValues] = useState([false]);
		const [checkboxOutput, setCheckboxOutput] = useState<any>();
		console.log("rerender checkbox");
		useEffect (() => {
			const checkboxUseState = [];
			const checkboxes = [];
			for ( let i=0;i<restrictValues.length;i++ ) {
				checkboxes.push({
					id: restrictValues[i],
					field: jobParts.field,
					screenName: restrictScreen[i],
					arrayNumber: i
				});
				checkboxUseState.push(false); // will have to read from value to ensure checks
			
			}
			if ( value ) {
				const valueArray = value.split(" ");
				for ( let i=0;i<valueArray.length;i++ ) {
					for ( let j=0;j<checkboxes.length;j++ ) {
						if ( valueArray[i] === checkboxes[j].id ) {
							checkboxUseState[checkboxes[j].arrayNumber] = true;
						}
					}
				}
			}
			setCheckboxValues(checkboxUseState);
			setCheckboxOutput(checkboxes);
		},[]); 
		
		
		const restrictValues = jobParts.restriction.split(",");
		const restrictScreen = jobParts.screenRestriction.split(",");
		const checkKeyPress = (e:any) => {
			const { key, keyCode } = e;
			if (keyCode === 13) {
				updateCheckboxValue();
			}
		};
		
		function updateCheckboxValue () {
			updateJobItem(jobParts,value,exception,false);
		}

		function setCheckboxValue (checked:boolean, arrayNumber:number) {
			const valueArray = [];
			
			const tempArray = checkboxValues;
			if ( checked ) {
				tempArray[arrayNumber] = true;
			}
			else {
				tempArray[arrayNumber] = false;
			}
			setCheckboxValues(tempArray);
			for ( let i=0;i<tempArray.length;i++ ) {
				if ( tempArray[i] ) {
					valueArray.push(checkboxOutput[i].id);
				}
			}
			setValue(valueArray.join(" "));
		}

		return (<><div className="title">{jobParts.screenName}</div>
			{checkboxOutput !== undefined ? (checkboxOutput.map((parts:any)=> (
				<div key={parts.id} onClick={() => {setExceptionText(exception);}}>
					<Checkbox label={parts.screenName} checked={checkboxValues[parts.arrayNumber]} formUpdate={(arg:boolean) => {setCheckboxValue(arg,parts.arrayNumber);}}/>
				</div>
			))) : null}
			{ exceptionFound !== "" ? (<div>
				<div className="blockSection">
					<div className="blockSection">
						{Lang.reasonPrefix} {jobParts.screenName}
					</div>
					<input
						name="whyNewValue"
						placeholder={Lang.enterReason}
						value={exception}
						onChange={(e) => {handleException(e.target.value,setException);}}
						onKeyDown={checkKeyPress}
						autoFocus
					/>
				</div>
			</div>) : null}
			<ButtonComponent action={() => {updateCheckboxValue();}} buttonText={Lang.add+" "+jobParts.screenName}/></>);
	}; 

	const UpdateBypass = ({jobParts, value}:any) => {
		const [textValue, setTextValue] = useState(value);
		const checkKeyPress = (e:any) => {
			const { key, keyCode } = e;
			if (keyCode === 13) {
				updateJobItem(jobParts,textValue,"",true);
			}
		};
		return (<><div className="title">{jobParts.screenName}</div>
			<div>
				<div className="blockSection">
					<input
						name="newText"
						placeholder={Lang.enterReason}
						autoFocus
						value={textValue}
						onChange={(e) => {setTextValue(e.target.value);}}
						onKeyDown={checkKeyPress}
					/>
				</div>
				<ButtonComponent action={() => {updateJobItem(jobParts,textValue,"",true);}} buttonText={Lang.bypassButton}/>
			</div>
		</>);
	};

	//recordException(taskResult.client_id,taskResult.location,taskResult.suite,employee_id,Number(meterDetails.meter_id),"Bypass Reason","","",Lang.bypassLabel+textValue);

	async function saveMeterInfo(note:string) {
		const updated_at = new Date().toISOString();
		await db.meterDetails.update(Number(meterDetails.meter_id), {note: note, updated_at: updated_at});
		success(Lang.saveMeterNote);
	}

	async function saveSuiteInfo(note:string) {
		const updated_at = new Date().toISOString();
		const suiteKey = taskResult.client_id+"-"+taskResult.location+"-"+taskResult.suite;
		await db.suites.update(suiteKey, {note: note, updated_at: updated_at});
		setCurrentSuiteNote(note);
		success(Lang.saveMeterNote);
	}

	async function saveDefectiveInfo(note:string) {
		if ( note.length === 0 ) {
			warning(Lang.noDetails);
			return;
		}
		else {
			const updated_at = new Date().toISOString();
			recordException(taskResult.client_id,taskResult.location,taskResult.suite,taskResult.employee_id,Number(meterDetails.meter_id),"Marked as Defective",meterDetails.serial_number,"",note);
			note = meterNote + "****** Defective ******" + note;
			await db.meterDetails.update(Number(meterDetails.meter_id), {note: note, updated_at: updated_at, defective: true});
			setSuiteMeterSelect([]);
			setMeterDetails([{"serial_number": "", "meter_id": 0, "note": "", "details": "","device_type": ""}]);
			success(Lang.defectiveMessage);
			setShowDefectModal(false);
		}
		
	}

	const CardSections = ({jobStatus}:any) => {
		const jobParts =[];
		let holdDiv = "";
		let upUntil = true;
		let stepAnchor = "";
		for ( let i=0;i<jobStatus.length;i++ ) {
			let divClass = "yellowBorder subInstallCard " + holdDiv;
			let titleLabel = Lang.suggested;
			let valueLabel = "";
			let cardValue:JSX.Element|string = "";
			let bodyOutput = (<div>{jobStatus[i].screenName}</div>);
			if ( jobStatus[i].status ) {
				divClass = "greenBorder subInstallCard";
				titleLabel = "";
				valueLabel = Lang.recordedValue;
				cardValue = checkForLang(jobStatus[i].value);
				if ( i !== 0 ) {
					setAnchorNumber(i+1);
				}
			}
			else if ( jobStatus[i].mandatory ) {
				divClass = "redBorder subInstallCard " + holdDiv;
				titleLabel = Lang.required;
			}
			if ( !jobStatus[i].status ) {
				upUntil = false;
			}
			if ( jobStatus[i].field_type === "radio" ) {
				if ( !jobStatus[i].status ) {
					if ( holdDiv !== "" ) {
						cardValue = (<ButtonComponent action={() => {warning(Lang.followSteps);}}buttonText={Lang.add+" "+jobStatus[i].screenName}/>);
					}
					else {
						cardValue = (<ButtonComponent action={() => {setCardValue(jobStatus[i]);setRadioModal(true);setShouldShow(true);}}buttonText={Lang.add+" "+jobStatus[i].screenName}/>);
						if ( !jobStatus[i].mandatory ) {
							cardValue = (<>{cardValue}<div className="smallText pointer link" onClick={() => {setCardValue(jobStatus[i]);setSkipModal(true);setShouldShow(true);}} >{Lang.bypassRequestText}</div></>);
						}
					}
				}
				else if ( jobStatus[i].ex_allowed ) {
					cardValue = (<>{cardValue} <div className="pointer smallText pointer link" onClick={() =>{setCardValue(jobStatus[i]);setValue(jobStatus[i].value);setRadioModal(true);setShouldShow(true);}}>{Lang.edit}</div></>);
				}
			}
			else if ( jobStatus[i].field_type === "text" || jobStatus[i].field_type === "text_pic") {
				if ( !jobStatus[i].status ) {
					if ( holdDiv !== "" ) {
						cardValue = (<ButtonComponent action={() => {warning(Lang.followSteps);}}buttonText={Lang.add+" "+jobStatus[i].screenName}/>);
					}
					else {
						cardValue = (<ButtonComponent action={() => {setCardValue(jobStatus[i]);setTextModal(true);setShouldShow(true);}}buttonText={Lang.add+" "+jobStatus[i].screenName}/>);
						if ( !jobStatus[i].mandatory ) {
							cardValue = (<>{cardValue}<div className="smallText pointer link" onClick={() => {setCardValue(jobStatus[i]);setSkipModal(true);setShouldShow(true);}} >{Lang.bypassRequestText}</div></>);
						}
					}
				}
				else if ( jobStatus[i].ex_allowed ) {
					cardValue = (<>{cardValue} <div className="pointer smallText pointer link" onClick={() =>{setCardValue(jobStatus[i]);setValue(jobStatus[i].value);setTextModal(true);setShouldShow(true);}}>{Lang.edit}</div></>);
				}
			}
			else if ( jobStatus[i].field_type === "checkbox" ) {
				if ( !jobStatus[i].status ) {
					if ( holdDiv !== "" ) {
						cardValue = (<ButtonComponent action={() => {warning(Lang.followSteps);}}buttonText={Lang.add+" "+jobStatus[i].screenName}/>);
					}
					else {
						cardValue = (<ButtonComponent action={() => {setCardValue(jobStatus[i]);setCheckboxModal(true);setShouldShow(true);}}buttonText={Lang.add+" "+jobStatus[i].screenName}/>);
						if ( !jobStatus[i].mandatory ) {
							cardValue = (<>{cardValue}<div className="smallText pointer  link" onClick={() => {setCardValue(jobStatus[i]);setSkipModal(true);setShouldShow(true);}} >{Lang.bypassRequestText}</div></>);
						}
					}
				}
				else {
					const tempArray = String(cardValue).split(" ");
					cardValue = "";
					for ( let i=0;i<tempArray.length;i++ ) {
						tempArray[i] = checkForLang(tempArray[i]);
					}
					cardValue = tempArray.join(" ");
					if ( jobStatus[i].ex_allowed ) {
						cardValue = (<>{cardValue} <div className="pointer smallText pointer link" onClick={() =>{setCardValue(jobStatus[i]);setValue(jobStatus[i].value);setCheckboxModal(true);setShouldShow(true);}}>{Lang.edit}</div></>);
					}
				}
			}
			else if ( jobStatus[i].field_type === "picture" ) {
				if ( holdDiv !== "" ) {
					cardValue = (<>{cardValue}<div className="inblock pointer smallImage" onClick={() => 
					{warning(Lang.followSteps);}}><img src={camera} alt={Lang.cameraIcon}/>
					</div></>);
				}
				else {
					cardValue = (<>{cardValue}<div className="inblock pointer smallImage" onClick={() => 
					{setImageDesc(jobStatus[i]);setShouldShowFull(true);setPhotoModal(true);}}><img src={camera} alt={Lang.cameraIcon}/>
					</div></>);
					if ( !jobStatus[i].status && !jobStatus[i].mandatory ) {
						cardValue = (<>{cardValue}<div className="smallText pointer  link" onClick={() => {setCardValue(jobStatus[i]);setSkipModal(true);setShouldShow(true);}} >{Lang.bypassRequestText}</div></>);
					}
				}
			}
			stepAnchor = "stepAnchor"+(i+1);
			bodyOutput = (<div>
				<div className="sectionInfoSmall">
					<div id={stepAnchor}>{Lang.stepLabel}{i+1}</div>
				</div>
				<div className="cardTitle">
					{titleLabel}{jobStatus[i].screenName}
				</div>
				<div>
					{valueLabel} {cardValue}
				</div>
			</div>);
			const output = (<div className={divClass}>{bodyOutput}</div>);
			jobParts.push({
				id: jobStatus[i].id,
				output: output,
				class: divClass
			});
			if ( holdDiv === "" && !upUntil && jobStatus[i].hold ) {
				holdDiv = "workFlowHalt";
			}
		}
		return (<>
			{jobParts.map((jobPart:any) => (
				<div key={jobPart.id} className="installCard">
					{jobPart.output}
				</div>
			))}</>
		);
	};

	return taskResult !== undefined ? (
		<div className="installations">
			{ propertyAddress !== "" ? (<>
				<div className="subTitle">
					{Lang.location}
				</div>
				<div className="section">
					<div className="subTitle">
						{propertyAddress} {Lang.suiteLabel}{taskResult.suite}
					</div>
					<div className="detailsText">
						<div className="details pointer link" onClick={() => {setModalValue(viewPropertyDetails);setShouldShow(true);}}>
							{Lang.details}
						</div>
						<div className="details pointer link" onClick={() => {setShouldShowPropOverride(true);}}>
							{Lang.override}
						</div>
					</div>
					<div className="sectionInfo pointer" onClick={() => setShouldShowSuiteNote(!shouldShowSuiteNote)}>
						{locationNoteTitle+" "}
						{suiteNote !== "" ? (<>
							<div className="smallText indent link">
								{Lang.editNote}
							</div>
							<div className="sectionInfoSmall ">
								{suiteNote}
							</div></>) : (
							<div className="smallText link">
								{Lang.addNote}
							</div>)}
					</div>
				</div></>) : null}
			<div className="subTitle">
				{Lang.devices}
			</div>
			<div className="section">	
				<div className="blockSection">
					<div className="third">
						<Select 
							options={suiteMeters}
							value={suiteMeterSelect}
							className="react-select-container"
							classNamePrefix="react-select"
							unstyled
							placeholder={Lang.selectMeterTypePlaceholder}
							onChange={handleMeter}
							maxMenuHeight={75}
						/> 
					</div>
				</div>
				{ Object.keys(suiteMeterSelect).length > 0 ? (
					<div className="blockSection">
						<div className="pointer" onClick={() => {setShowSerialNumberInput(true);}}>
							{meterDetails.serial_number !== "" ? (<div className="sectionInfoSmall beside link">{Lang.updateSerialNumber}</div>) : (<div><div className="sectionInfoSmall beside link">{Lang.addSerialNumber}</div> </div>)}
						</div>
						{meterDetails.serial_number !== "" ? (<>
							<div className="pointer sectionInfoSmall beside indent link" onClick={() => {setShowDefectModal(true);}}>{Lang.defectiveLabel}</div>
							<div className="blockSection">
								<div className="sectionInfo pointer" onClick={() => setShouldShowNote(!shouldShowNote)}>
									{deviceNoteTitle+" "}
									{meterNote !== "" ? (
										<div className="smallText link">
											{Lang.editNote}
										</div>) : (
										<div className="smallText link">
											{Lang.addNote}
										</div>)}
								
								</div>
								{meterNote !== "" ? (
									<div className="sectionInfoSmall indent">
										{meterNote}
									</div>
								) : null}
							</div></>) 
							: null}
					</div>
				) : null}
			</div>
			
			{ Object.keys(jobStatus).length > 0 && meterDetails.serial_number !== "" ? 
				(<><div className="subTitle">
					{Lang.installTasks}
				</div>
				<div className="section">	
					<BasicProgressBarWithLabel currentValue={currentProgress} label={Lang.jobProgress} />
					<CardSections jobStatus={jobStatus}/>
				</div>
				</>) : null}
			<Modal shouldShow={shouldShowPropOverride} onRequestClose={() => setShouldShowPropOverride(false)}> 
				<MeterOverride taskResult={taskResult} propertyDetails={propertyDetails} onRequestClose={() => setShouldShowPropOverride(false)} setMeterSelect={(value: any) => {setSuiteMeterSelect(value);}}/>
			</Modal>
			<Modal shouldShow={shouldShowPropDetails} onRequestClose={() => setShouldShowPropDetails(false)}> 
				<PropertyDetails taskResult={taskResult} onRequestClose={() => setShouldShowPropDetails(false)} setPropertyDetails={(value: any) => setPropertyDetails(value)}/>
			</Modal>
			<Modal shouldShow={showSerialNumberInput} onRequestClose={() => setShowSerialNumberInput(false)}> 
				<SerialNumber taskResult={taskResult} propertyDetails={propertyDetails} meterDetails={meterDetails} onRequestClose={() => setShowSerialNumberInput(false)} setMeterSelect={(value: any) => {setSuiteMeterSelect(value);}}/>
			</Modal>
			<Modal shouldShow={shouldShow} onRequestClose={() => setShouldShow(false)}> 
				{radioModal ? (<UpdateRadioModal jobParts={cardValue} value={value} setValue={(arg:string) => {setValue(arg);}} exceptionText={exceptionText} setExceptionText={(value:string) => {setExceptionText(value);}}/>) : null}
				{checkboxModal ? (<UpdateCheckboxModal jobParts={cardValue} value={value} setValue={(arg:string) => {setValue(arg);}} exceptionText={exceptionText} setExceptionText={(value:string) => {setExceptionText(value);}}/>) : null}
				{textModal ? (<UpdateTextModal jobParts={cardValue} value={value} />) : null}
				{skipModal ? (<UpdateBypass jobParts={cardValue} value={value}/>) : null}
				{modalValue}
			</Modal>
			<ModalFull shouldShow={shouldShowFull} onRequestClose={() => {setShouldShowFull(false);}}>
				{photoModal ? (<Camera 
					saveImage={(image:Blob) => {saveTheImage(image,meterDetails.serial_number,imageDesc.field);}}
					updateJob={() => {updateJobItem(imageDesc,imageDesc.field,"",false);}}
				/>) : null}
			</ModalFull>
			<Modal shouldShow={shouldShowNote} onRequestClose={() => {setShouldShowNote(false);setMeterNote(meterDetails.note);}}>
				<p>{deviceNoteDescription}</p>
				<textarea 
					rows={4}
					wrap="hard"
					name="meterNote"
					autoFocus
					value={meterNote}
					className="taskNotepad"
					placeholder={devicenoteplaceholder}
					onChange={(e) => {handleNote(e.target.value,setMeterNote);}}
				/>
				<br></br>
				<ButtonComponent action={() => {saveMeterInfo(meterNote);setShouldShowNote(false);}} buttonText={Lang.submit}/>
				<div className="sectionInfoSmall subSection">{Lang.characterLeft} {250-meterNote.length}</div>
			</Modal>
			<Modal shouldShow={shouldShowSuiteNote} onRequestClose={() => {setShouldShowSuiteNote(false);setSuiteNote(currentSuiteNote);}}>
				<p>{Lang.suiteNoteDescription}</p>
				<textarea 
					rows={4}
					wrap="hard"
					name="suiteNote"
					autoFocus
					value={suiteNote}
					className="taskNotepad"
					placeholder={locationnoteplaceholder}
					onChange={(e) => {handleNote(e.target.value,setSuiteNote);}}
				/>
				<br></br>
				<ButtonComponent action={() => {saveSuiteInfo(suiteNote);setShouldShowSuiteNote(false);}} buttonText={Lang.submit}/>
				<div className="sectionInfoSmall subSection">{Lang.characterLeft} {250-suiteNote.length}</div>
			</Modal>
			<Modal shouldShow={showDefectiveModal} onRequestClose={() => {setShowDefectModal(false);}}>
				<p>{Lang.defectiveNoteDescription}</p>
				<textarea 
					rows={4}
					wrap="hard"
					name="defectiveNote"
					autoFocus
					value={defectiveNote}
					className="taskNotepad"
					placeholder={Lang.defectivenoteplaceholder}
					onChange={(e) => {handleNote(e.target.value,setDefectiveNote);}}
				/>
				<br></br>
				<ButtonComponent action={() => {saveDefectiveInfo(defectiveNote);}} buttonText={Lang.submit}/>
				<div className="sectionInfoSmall subSection">{Lang.characterLeft} {250-defectiveNote.length}</div>
			</Modal>
			

		</div>) 
		: <p>{Lang.loading}</p>;

		
};

export {Installation};
//<SuiteInput taskResult={taskResult} setShouldShow={() => setShouldShow(false)} setSuiteSelect={(value:any) => setSuiteSelect(value)}/>
			
// for image display { storedImage !== "" ? (<div className="image"><img src={storedImage} className="serialImage"></img></div>) : null }
//<ButtonComponent action={() => {setModalValue(updateModal("device_pic","yes"));setShouldShow(true);}}buttonText="two"/>
//<ButtonComponent action={() => {setModalValue(updateModal("note","This is a note"));setShouldShow(true);}}buttonText="three"/>
//<ButtonComponent action={() => {saveSuites(taskResult,608,"water",jobType,(value:any) => {setSuiteSelect(value);});}} buttonText="Make Suite"/>
