import React from "react";
import PropTypes from "prop-types";
import { randomNumberInRange } from "../services/tools";
import {Notification} from "./Notifications";

function NotificationsManager({ setNotify }:any) {
	const [notifications, setNotifications] = React.useState([]);

	const createNotification = ({ color, autoClose, children }:any) => {
		setNotifications((prevNotifications):any => {
			const random = randomNumberInRange(0,100);
			const milliseconds = Date.now();
			const randomNumber = String(random)+String(milliseconds).slice(-5);
			console.log(randomNumber);
			return [
				...prevNotifications,
				{
					children,
					color,
					autoClose,
					id: Number(randomNumber),
				},
			];
		});
	};
	React.useEffect(() => {
		setNotify(({ color, autoClose, children }:any) =>
			createNotification({ color, autoClose, children })
		);
	}, [setNotify]);

	const deleteNotification = (id:number) => {
		const filteredNotifications = notifications.filter(
			(_, index) => id !== index,
			[]
		);
		setNotifications(filteredNotifications);
	};

	return notifications.map(({ id, ...props }:any, index) => (
		<Notification
			key={id}
			onDelete={() => deleteNotification(index)}
			{...props}
		/>
	));
}

NotificationsManager.propTypes = {
	setNotify: PropTypes.func.isRequired,
};

export {NotificationsManager};