import {db} from "../services/db.tsx";

async function getToken (employee_id) {
	let token = "";
	await db.systemControls.where("name").equalsIgnoreCase("token").toArray().then((value) => {
		if ( value[0] ) {
			const currentTime = new Date();
			const tokenTime = new Date(value[0].update);
			if ( tokenTime.setHours(tokenTime.getHours()+12) > currentTime ) {
				token = value[0].value;
			}
		}
	});
	return token;
}

async function setToken (employee_id,token) {
	await removeToken();
	addSystemControls("token",token,employee_id,false);
	return "ok";
}

async function removeToken() {
	await db.systemControls.where("name").equalsIgnoreCase("token").delete();
}
async function getLoggedInUser () {
	let value = {value: "", employee_id: 0};
	try {
		await db.systemControls.where("name").equalsIgnoreCase("current_user").toArray().then((values) => {
			if ( values ) {
				if ( values[0] !== undefined) {
					value = values[0];
				}
			}
         
		});
	}
	catch (error) {
		console.log(error);
	}
	return value;
}

async function addSystemControls(name, value, employee_id, consent) {
	const update = new Date();
	db.systemControls.add({
		name: name,
		value: value,
		employee_id: employee_id,
		consent: consent,
		update: update
	});
}

export {getToken, setToken, removeToken, getLoggedInUser};