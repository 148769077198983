import { createPortal } from "react-dom";
import { createRoot } from "react-dom/client";
import PropTypes from "prop-types";
import {NotificationsManager} from "./NotificationManager";
import React from "react";

const portalId = "notifyContainer";
const timeToDelete=300;
const timeToClose=6000;
const containerElement = createContainer();
let notify;

function Notification({color, onDelete, autoClose = true, children}) {
	const rootClass = "notification "+color;
	const openClass = rootClass+ " slideIn";
	const closeClass = rootClass+ " slideOut";
	//const container = createContainer();
	const [isClosing, setIsClosing] = React.useState(false);

	React.useEffect(() => {
		if (isClosing) {
			const timeoutId = setTimeout(onDelete, timeToDelete);

			return () => {
				clearTimeout(timeoutId);
			};
		}
	}, [isClosing, onDelete]);

	React.useEffect(() => {
		if (autoClose) {
			const timeoutId = setTimeout(() => setIsClosing(true), timeToClose);

			return () => {
				clearTimeout(timeoutId);
			};
		}
	}, [autoClose]);

	return createPortal(<div className={!isClosing ? openClass : closeClass } onClick={() => {setIsClosing(true);}}>
		{children}
		<button onClick={() => setIsClosing(true)} type="button" className="closeButton">X
		</button>
	</div>,containerElement
	);
}

const Color = {
	info: "info",
	success: "success",
	warning: "warning",
	error: "error",
};

Notification.propTypes = {
	color: PropTypes.oneOf(Object.keys(Color)),
	children: PropTypes.oneOfType([PropTypes.element,PropTypes.string]),
};

Notification.defaults = {
	color: "info",
};

function createContainer() {
	
	let element = document.getElementById(portalId);

	if (element) {
		return element;
	}

	element = document.createElement("div");
	element.setAttribute("id", portalId);
	element.className="notificationContainer";
	document.body.appendChild(element);
	return element;
} 

if (!React.isValidElement("NotificationsManager")) {
	const element = document.getElementById(portalId);
	const root = createRoot(element);
	root.render(
		<NotificationsManager
			setNotify={(notifyFn) => {
				notify = notifyFn;}}
		/>);
}

function info(children, autoClose) {
	return notify({
		color: Color.info,
		children,
		autoClose,
	});
}

function success(children, autoClose) {
	return notify({
		color: Color.success,
		children,
		autoClose,
	});
}

function warning(children, autoClose) {
	return notify({
		color: Color.warning,
		children,
		autoClose,
	});
}

function error(children, autoClose) {
	return notify({
		color: Color.error,
		children,
		autoClose,
	});
}

export {NotificationsManager, createContainer, Notification, Color, error, warning, success, info };