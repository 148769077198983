import {lang} from "../components/appLang.js";
import optionsIcon from "../images/options.png";
import logoutIcon from "../images/logout.png";
import taskIcon from "../images/task-planning.png";
import {Modal} from "../components/Modal";
import { useState, useEffect } from "react";
import { refreshStaticTable } from "../components/staticRetrieval.js";
import { dbRemove, initializeDB } from "../components/dbSections.jsx";
import  { TimeClock } from "../components/timeClock";
import { warning } from "../components/Notifications.js";
import { PwaStatus } from "../components/PwaStatus";

const Lang = lang();

function PageFooter({taskResult,setTaskResult,setTicketID, setLoginState, setEmployee_id, employee_id}:any) {
	const [shouldShowTime, setShouldShowTime] = useState(false);
	const [shouldShowOptions, setShouldShowOptions] = useState(false);
	const [shouldShowPwaStatus, setShouldShowPwaStatus] = useState(false);
	const [taskState, setTaskState] = useState(Lang.startTask);

	useEffect (() => {
		if ( Number(taskResult.openId) !== 0 ) {
			setTaskState(Lang.endTask);
		}
		else {
			setTaskState(Lang.startTask);
		}
	},[taskResult]);

	return(<><div className="page_footer centre">
		<div className="timeclock centre">
			<div className="footerCard pointer" onClick={() => {setShouldShowOptions(true);}}>
				<div className="blockSection centre">
					<img className="footerImage" src={optionsIcon}/> 
				</div>
				<div className="blockSection centre">
					{Lang.navigate}
				</div>
			</div>
			<div className="footerCard pointer" onClick={() => setShouldShowTime(!shouldShowTime)}>
				<div className="blockSection centre">
					<img className="footerImage" src={taskIcon}/> 
				</div>
				<div className="blockSection centre">
					{taskState}
				</div>
			</div>
			<div className="footerCard pointer" onClick={() => {setLoginState();setEmployee_id(0);localStorage.removeItem("loggedIn");}}>
				<div className="blockSection centre">
					<img className="footerImage" src={logoutIcon}/> 
				</div>
				<div className="blockSection centre">
					{Lang.logoutText}
				</div>
			</div>
		</div>
	
		<Modal shouldShow={shouldShowTime} onRequestClose={() => setShouldShowTime(false)}>
			<TimeClock 
				onRequestClose={() => setShouldShowTime(false)} 
				taskResult={taskResult} 
				setTaskResult={(value:any) => {setTaskResult(value);}} 
				setTicketID={(value:number) => setTicketID(value)}
				employee_id={employee_id}
			/>
		</Modal>
		<Modal shouldShow={shouldShowPwaStatus} onRequestClose={() => setShouldShowPwaStatus(false)}>
			<PwaStatus/>
		</Modal>
		<Modal shouldShow={shouldShowOptions} onRequestClose={() => setShouldShowOptions(false)}>
			<h3>{Lang.optionsTitle}</h3>
			<div className="blockSection">
				<div className="beside link pointer" onClick={() => {setShouldShowTime(true);setShouldShowOptions(false);}}>
					{taskState}
				</div>
			</div>
			<div className="blockSection">
				<div className="subTitle">
					{Lang.dbOptions}
				</div>
			</div>
			<div className="blockSection">
				<div className="beside link indent pointer" onClick={()=>{refreshStaticTable("worklogtasks", employee_id, true);}}>{Lang.updateStaticWorktask}</div>
			</div>
			<div className="blockSection">
				<div className="beside link indent pointer" onClick={()=>{refreshStaticTable("inputcontrols", employee_id, true);}}>{Lang.updateStaticInput}</div>
			</div>
			<div className="blockSection">
				<div className="beside link indent pointer" onClick={()=>{warning(Lang.loading);refreshStaticTable("property_details",employee_id,  true);refreshStaticTable("properties",employee_id);}}>{Lang.updateStaticProperties}</div>
			</div>
			<div className="blockSection">
				<div className="beside link indent pointer" onClick={()=>{refreshStaticTable("suites",employee_id,  true);}}>{Lang.updateStaticSuites}</div>
			</div>
			<div className="blockSection">
				<div className="beside link indent pointer" onClick={()=>{refreshStaticTable("clients",employee_id,  true);}}>{Lang.updateStaticClients}</div>
			</div>
			<div className="blockSection">
				<div className="beside link indent pointer" onClick={()=>{initializeDB(employee_id);}}>{Lang.updateAllTables}</div>
			</div>
			<div className="blockSection">
				<div className="beside link indent pointer" onClick={()=>{dbRemove("SagacityApp");}}>{Lang.deleteDB}</div>
			</div>
			<div className="blockSection">
				<div className="subTitle">
					{Lang.tools}
				</div>
			</div>
			<div className="blockSection">
				<div className="beside link indent pointer" onClick={() => {setShouldShowPwaStatus(true);setShouldShowOptions(false);}}>Service Worker Check</div>
			</div>
			<div className="blockSection"></div>
		</Modal>
	</div></>);
}

export { PageFooter };