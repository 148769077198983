import {db} from "./db";
import {error} from "../components/Notifications";

async function getOpenTask (employee_id: number) {
	const date = new Date();
	const startTime = new Date(date.getFullYear(),date.getMonth(),date.getDate(),0,0,0);
	const endTime = new Date();
	try {
		const dayTask = await db.workLog.where(["employee_id","date"]).between([employee_id,startTime],[employee_id,endTime],true,true).toArray();
		let openTask = -1;
		if ( dayTask && dayTask.length > 0 ) {
			for (let i = 0; i < dayTask.length; i++) {
				if ( dayTask[i].end_time === "" ) {
					openTask = i;
					i = dayTask.length;
				}
			}
		}
		if ( openTask >= 0  ) {
			const majorTask:any = await db.worklogtasks.get({id: dayTask[openTask].major_task});
			const minorTask:any = await db.worklogtasks.get({id: dayTask[openTask].minor_task});
			const result:{[key: string]: string|number|Date} = {
				"openId": dayTask[openTask].id?.toString() ?? 0,
				"majorTask": majorTask?.short_description,
				"majorTaskNumber": dayTask[openTask].major_task,
				"minorTaskNumber": dayTask[openTask].minor_task,
				"minorTask": minorTask?.short_description,
				"start_time": dayTask[openTask].start_time,
				"note": dayTask[openTask].note,
				"mileage": dayTask[openTask].mileage,
				"location": dayTask[openTask].location,
				"date": dayTask[openTask].date,
				"client_id": dayTask[openTask].client_id,
				"employee_id": employee_id,
				"suite": dayTask[openTask].suite_id
			};
			return result;
		}
	}
	catch (catch_error) {
		error(error);
		const result:{[key: string]: string}= {"openId": "0"};
		return result;
	}	
	const result:{[key: string]: string}= {"openId": "0"};
	return result;
}

export {getOpenTask};