import "./App.css";
import { useState, useEffect } from "react";
import {lang} from "./components/appLang.js";
import PageHeader from "./layout/Header";
import {PageFooter} from "./layout/footer";
import {Installation} from "./components/installations";
import { TimeoutProvider } from "./components/timeout";
import { GeneralUsage } from "./components/disclaimer";
import {Modal} from "./components/Modal";
import { initializeDB } from "./components/dbSections";
import { info, success, warning, error } from "./components/Notifications";
import { db } from "./services/db";
import { getOpenTask } from "./services/task";
import { successGeoCall,errorGeoCall } from "./services/geoLocate";
const Lang = lang();
import { geoRetrieveVar, swRefresh } from "./controlVariables";
import { NavigatorOnLine, getOnLineStatus, StatusDiv } from "./components/onlineCheck";
import { LoginPage } from "./components/Login";
import { StartPage } from "./components/startPage";
import useKeyboardVisibility from "./services/view_adjuster";
import { saveGeoLocations } from "./services/db_serviceworker";

const geoRetrieveInterval = geoRetrieveVar*60*1000;
const demo = false;

function App() {
	//const [showNotification, setNotification] = useState(false);
	const [taskResult, setTaskResult] = useState<any[]>([]);
	const [openId, setOpenId] = useState(0);
	const [major_task, setMajorTask] = useState(0);
	const [onlineStatus, setOnlineStatus] = useState(false);
	const [loginState, setLoginState] = useState(false);
	const [geoInterval, setGeoInterval] = useState<any>();
	const [shouldShow, setShouldShow] = useState(false);
	const [currentlyUpdating, setCurrentlyUpdating] = useState(false);
	const {isKeyboardVisible, keyboardOffset} = useKeyboardVisibility();
	const [generalDisclaimer, setGeneralDisclaimer] = useState(false);
	const [employee_id, setEmployee_id] = useState(0);
	const [userName, setUserName] = useState("");
	

	useEffect(() => {
		console.log("setting geo interval");
		navigator.geolocation.getCurrentPosition(successGeoCall,errorGeoCall);
		const geofunction = setInterval(async ()=>{navigator.geolocation.getCurrentPosition(successGeoCall,errorGeoCall);},geoRetrieveInterval);
		setGeoInterval(geofunction);
		console.log(geofunction);
		setOnlineStatus(getOnLineStatus());
		return () => {clearInterval(geoInterval);}; // unmounts the interval when rendered again
	},[]);

	useEffect (() => {
		if ( onlineStatus && loginState && generalDisclaimer ) {
			initializeDB(employee_id);
		}
		if ( !loginState ) {
			if ( geoInterval !== "" ) {
				console.log("stopping geo");
				clearInterval(geoInterval);
				setGeoInterval("");
			}
		}
		else {
			if ( geoInterval === "" ) {
				console.log("starting geo ");
				navigator.geolocation.getCurrentPosition(successGeoCall,errorGeoCall);
				const geofunction = setInterval(async ()=>{navigator.geolocation.getCurrentPosition(successGeoCall,errorGeoCall);},geoRetrieveInterval);
				setGeoInterval(geofunction);
			}
			
		}
	},[onlineStatus,loginState,generalDisclaimer,employee_id]);

	useEffect(() => {
		if ( loginState ) {
			getSystemControls("generalDisclaimer", employee_id);
			if ( !generalDisclaimer ) {
				setShouldShow(true);
			}
			else {
				setShouldShow(false);
			}
		}
	},[loginState,generalDisclaimer]);

	useEffect(() => {
		if ( loginState && employee_id !== 0 ) {
			getOpenTask(employee_id).  // get the current open task
				then((result:any) => {
					if ( result !== undefined ) {
						setTaskResult(result);
						setOpenId(result.openId);
						setMajorTask(result.majorTaskNumber);
					}
					console.log("got new open task");
				// if ( result.openId !== "0" ) {
				// 	setOpenTask(result.openId);
				// 	let value:keyof typeof Lang = result.majorTask;
				// 	setOpenMajor(Lang[value]);
				// 	if ( result.minorTask !== undefined ) {
				// 		value = result.minorTask.replace(/:.*/,"");
				// 		setOpenMinor(Lang[value]);
				// 	}
				// 	setTaskNote(result.note);
				// 	setTaskMileage(result.mileage);
				// 	setOpenStart(result.start_time);
				// 	setOpenMajorNumber(result.majorTaskNumber);
				// }
				});
		}		
		
		
	},[openId,major_task,loginState, employee_id]);

	useEffect (() => {
		// set the nav bottom here
		const notifyId = document.getElementById("notifyContainer");
		if ( notifyId ) {
			if ( isKeyboardVisible ) {
				notifyId.style.bottom = keyboardOffset+"px";
				//alert("setting keyboard offset");
			}
			else {
				notifyId.style.bottom = "65px";
			}
		}
	},[isKeyboardVisible]);

	async function getSystemControls (controlName: string, employee_id: number) {
		await db.systemControls.where({name: controlName, employee_id: employee_id}).toArray().then((value:any) => {
			if ( value.length > 0 ) {
				if ( controlName === "generalDisclaimer" ) {
					setGeneralDisclaimer(value[0].consent);
				}
			}
			else {
				setGeneralDisclaimer(false);
			}
		});
	}

	function invokeServiceWorkerUpdateFlow(registration: ServiceWorkerRegistration) {  // for service worker update refresh
		const notification = window.confirm(Lang.newServiceWorker);
		//console.log(registration.waiting);
		if ( notification && registration.waiting ) {
			registration.waiting.postMessage("SKIP_WAITING");
		}
	}

	if ("serviceWorker" in navigator) {// check if the browser supports serviceWorker at all
		window.addEventListener("load", async () => {
			const registration = await navigator.serviceWorker.register("./service-worker.js");
			if (registration.waiting) {// ensure the case when the updatefound event was missed
				invokeServiceWorkerUpdateFlow(registration);
			}
			registration.addEventListener("updatefound", () => {// detect Service Worker update available and wait for it to become installed
				if (registration.installing) {
					registration.installing.addEventListener("statechange", () => {// wait until the new Service worker is actually installed (ready to take over)
						if (registration.waiting) {
							if (navigator.serviceWorker.controller) {// if there's an existing controller (previous Service Worker), show the prompt
								invokeServiceWorkerUpdateFlow(registration);
							} else {
								console.log("Service Worker initialized for the first time");// otherwise it's the first install, nothing to do
							}
						}
					});
				}
			});
			setInterval(async () => {setCurrentlyUpdating(false);console.log("interval chec",currentlyUpdating);registration.update();},swRefresh);  // setting up timed checks for new service worker

			let refreshing = false;
   
			navigator.serviceWorker.addEventListener("controllerchange", () => { // detect controller change and refresh the page
				if (!refreshing) {
					console.log("reloading window");
					window.location.reload();
					refreshing = true;
				}
			});
		});
	}
	else {
		alert("This phone does not support the OnSiteAssist App");
	}

	const message = "this is the message this is the message this is the message";

	return (
		<><NavigatorOnLine setStatus={(status:boolean) => {setOnlineStatus(status);}}/>
			{ employee_id === 0 ? (<LoginPage 
				setLoginState={() => {setLoginState(true);}} 
				setOpenId={() =>{setOpenId(0);}} 
				setEmployee_id={(value:number) => {setEmployee_id(value);}} 
				onlineStatus={onlineStatus}
				setUserName={(value:string) => {setUserName(value);}}
			/>) : 
				(<><TimeoutProvider 
					loginState={loginState} 
					setLoginState={(value:boolean) =>{setLoginState(value);}} 
					setOpenId={() =>{setOpenId(0);}}
					resetEmployeeId={() => {setEmployee_id(0);localStorage.removeItem("loggedIn");}}>
					<div className="App">
						<StatusDiv onlineStatus={onlineStatus}/>
						{Number(openId) === 0 ? (
							<StartPage userName={userName} employee_id={employee_id}/>
						) : null }
						{ Number(openId) !== 0 && loginState ? (
							<PageHeader taskResult={taskResult} onlineStatus={onlineStatus} setTaskResult={(value:any) => {setTaskResult(value);}} setTicketID={(value:number) => {setOpenId(value);}}/>) : null} 
						{Number(openId) !== 0 && major_task === 1 ? (<Installation taskResult={taskResult} loginState={loginState}/>) : null}
						{Number(openId) !== 0 && major_task === 2 ? (<>Commissioning Coming Soon</>) : null}
						{Number(openId) !== 0 && major_task === 3 ? (<>Repairs Coming Soon</>) : null}
						{Number(openId) !== 0 && major_task === 6 ? (<>Offsite Coming Soon</>) : null}
					</div>
					{ loginState ? (<PageFooter 
						taskResult={taskResult} 
						setTaskResult={(value:any) => {setTaskResult(value);}} 
						setTicketID={(value:number) => {setOpenId(value);}} 
						setLoginState={() => {setLoginState(false);}} 
						setEmployee_id={(value:number) => {setEmployee_id(value);}} 
						employee_id={employee_id}
						resetWorklogId={() => {setOpenId(0);}}/>
					) : null }
					<div onClick={() => {saveGeoLocations();}}>test post requests</div>
				</TimeoutProvider>
				<div className="FooterBlankArea"></div></>)
			} 
			<Modal shouldShow={shouldShow} onRequestClose={() => {
				if ( !generalDisclaimer ) {
					setShouldShow(false);
					setLoginState(false);
					setEmployee_id(0);
				}
				else {
					setShouldShow(false);
				}}}>
				<GeneralUsage onRequestClose={() => {setShouldShow(false);}} logout={() =>{setLoginState(false);setEmployee_id(0);}} approved={() => {setGeneralDisclaimer(true);}} employee_id={employee_id}/>
			</Modal>
			<div className="App">
				{ demo ? (<><div className="blockSection">
					<h1>Notification Demo</h1>
				</div>
					
					
				<button onClick={() => info(message, true)}>Info</button>
				<button onClick={() => success(message, true)}>Success</button>
				<button onClick={() => warning(message, true)}>Warning</button>
				<button onClick={() => error(message, true)}>Error</button>				
					
				<p>{openId}</p>
				<br/>
				<br/>
				</>) : null}
			</div>
		</>
	);
}

//

export default App;
