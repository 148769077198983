import {db} from "../services/db";
import { useEffect, useState } from "react";
import {lang} from "./appLang.js";
import { ButtonComponent, handleException, checkInput } from "./FormComponents";
import { warning } from "./Notifications.js";
import { overrideElectral, serialNumberParm } from "../controlVariables.js";
import { recordException } from "./dbSections";
import {QRScanner} from "./Scanner";
import {Modal, ModalFull } from "./Modal";
import { saveImage } from "../services/images";
import camera from "../icons/camera.png";
import { checkForLang } from "../services/tools";

const Lang = lang();

async function newMeter(taskResult:any, propertyDetails: any, device_type:any, note:string) {
	const details:any = await db.meterDetails.where({client_id: taskResult.client_id, property_id: taskResult.location, suite: taskResult.suite}).toArray();
	if ( note === "" ) {
		if ( details ) {
			let btuCount = 0;
			for (let i = 0; i < details.length; i++) {
				if ( !details[i].defective ) {
					if ( details[i].device_type === device_type && device_type !== "btu" ) {
						console.log("one");
						return details[i].meter_id;
					}
					else if ( details[i].device_type === device_type && device_type === "btu" ) {
						console.log("two");
						if ( propertyDetails.pipe_system === 2 ) {
							return details[i].meter_id;
						}
						else {
							btuCount++;
						}
					}
				}
				
			}
			if ( propertyDetails.pipe_system === 4 && btuCount > 1 ) {
				return 0;
			}
		} 
	}
	if ( device_type === "electric" && !overrideElectral && note !== "" ) {
		for (let i = 0; i < details.length; i++) {
			if ( details[i].device_type === device_type ) {
				warning(Lang.oneElectrical);
				return 0;
			}
		}
	}
	const updated_at = new Date();
	let meterSelect; 
	await db.meterDetails.add({
		client_id: Number(taskResult.client_id),
		api_meter_id: 0,
		updated_at: updated_at,
		date: updated_at,
		details: "",
		"device_type": device_type,
		property_id: taskResult.location,
		serial_number: "",
		suite: taskResult.suite,
		"note": "",
		defective: false
	}).then((id) => {
		const desc = checkForLang(device_type);
		meterSelect = {"value": id, 
			"label": desc+" - "+Lang.noSerialNumber};
		if ( note !== "" ) {
			recordException(taskResult.client_id, taskResult.location, taskResult.suite, taskResult.employee_id, id, "override","",id,note);
		}
	});
	return meterSelect;
	
}

async function getSuiteMeter (meter_id:any) {
	const now = new Date();
	const meterObj = {"serial_number": "", "meter_id": 0, "note": "", "details": "","device_type": "","start_time": now};
	if ( meter_id.value ) {
		await db.meterDetails.where({meter_id: meter_id.value}).toArray().then((value:any) => {
			meterObj.serial_number = value[0].serial_number;
			meterObj.meter_id = meter_id.value;
			meterObj.note = value[0].note;
			meterObj.details = value[0].details;
			meterObj.device_type = value[0].device_type;
		});
	}
	return meterObj;
}

async function getSuiteMeters (propertyDetails: any, client_Id: number, location: number, suite: any, ) {
	let serialNumber = "";
	const suiteMeters:any = [];
	const storedTypes:any = [];
	if ( ! propertyDetails ) {
		return;
	}
	await db.meterDetails.where({client_id: client_Id, property_id: location, suite: suite}).toArray().then((details:any) => {
		if ( details ) {
			for (let i = 0; i < details.length; i++) {
				if ( !details[i].defective ) {
					const desc = checkForLang(details[i].device_type);
					serialNumber = Lang.noSerialNumber;
					if ( details[i].serial_number !== "" ) {
						serialNumber = details[i].serial_number;
					}
					suiteMeters.push({
						"value": details[i].meter_id, 
						"label": desc+" - "+serialNumber
					});
					storedTypes.push(details[i].device_type);
				}
			}
		}
		if ( propertyDetails.meter_electric && !storedTypes.includes("electric") ) {
			suiteMeters.push({
				"value": "electric-new", 
				"label": Lang.electric+" - "+Lang.newRecord
			});
		}
		if ( propertyDetails.meter_gas && !storedTypes.includes("gas") ) {
			suiteMeters.push({
				"value": "gas-new", 
				"label": Lang.gas+" - "+Lang.newRecord
			});
		}
		if ( propertyDetails.meter_water && !storedTypes.includes("water") ) {
			suiteMeters.push({
				"value": "water-new", 
				"label": Lang.water+" - "+Lang.newRecord
			});
		}
		if ( propertyDetails.meter_water_hot && !storedTypes.includes("hot_water") ) {
			suiteMeters.push({
				"value": "hot_water-new", 
				"label": Lang.hot_water+" - "+Lang.newRecord
			});
		}
		if ( propertyDetails.meter_btu  ) {
			if ( propertyDetails.pipe_system === 2 && !storedTypes.includes("btu") ) {
				suiteMeters.push({
					"value": "btu-new", 
					"label": Lang.btu+" - "+Lang.newRecord
				});
			}
			else if ( propertyDetails.pipe_system === 4 ) {
				let btuCount = 0;
				for ( let i=0;  i < storedTypes.length ; i++ ) {
					if ( storedTypes[i] == "btu" ) {
						btuCount++;
					}
				}
				if ( btuCount === 1 ) {
					suiteMeters.push({
						"value": "btu-new", 
						"label": Lang.btu+" - "+Lang.newRecord
					});
				}
				if ( btuCount === 0 ) {
					suiteMeters.push({
						"value": "btu-new2", 
						"label": Lang.btu+" - "+Lang.newRecord
					});
					suiteMeters.push({
						"value": "btu-new1", 
						"label": Lang.btu+" - "+Lang.newRecord
					});
				}
			}
		}
	});
	return suiteMeters;
}

const MeterOverride = ({taskResult, propertyDetails, onRequestClose, setMeterSelect}:any) => {
	let electrical = true;

	if ( !overrideElectral ) {
		electrical = false;
	}

	return (<>
		<div className="subTitle">{propertyDetails.street}, {propertyDetails.city}</div>
		<div className="indent">
			<div className="subTitle">{Lang.overrideTitle}</div>
			<div className="smallText">{Lang.overrideMessage}</div>
		</div>
		<div className="indent">
			{electrical ? (<div className="blockSection"><div className="pointer beside link" onClick={() => {
				newMeter(taskResult, propertyDetails, "electric", "Electrical Meter Override").then((value) => setMeterSelect(value));onRequestClose();}}>{Lang.electricOverride}</div></div>) : null}
			<div className="blockSection"><div className="pointer beside link" onClick={() => {
				newMeter(taskResult, propertyDetails, "gas", "Gas Meter Override").then((value) => setMeterSelect(value));onRequestClose();}}>{Lang.gasOverride}</div></div>
			<div className="blockSection"><div className="pointer beside link" onClick={() => {
				newMeter(taskResult, propertyDetails, "water", "Water Meter Override").then((value) => setMeterSelect(value));onRequestClose();}}>{Lang.waterOverride}</div></div>
			<div className="blockSection"><div className="pointer beside link" onClick={() => {
				newMeter(taskResult, propertyDetails, "hot_water", "Hot Water Meter Override").then((value) => setMeterSelect(value));onRequestClose();}}>{Lang.hot_waterOverride}</div></div>
			<div className="blockSection"><div className="pointer beside link" onClick={() => {
				newMeter(taskResult, propertyDetails, "btu", "BTU Meter Override").then((value) => setMeterSelect(value));onRequestClose();}}>{Lang.btuOverride}</div></div>
			<div className="blockSection"></div>
		</div>
	</>
	);
};

const SerialNumber = ({taskResult, propertyDetails, meterDetails, onRequestClose, setMeterSelect}:any) => {
	const [serialNumber, setSerialNumber] = useState(meterDetails.serial_number);
	const [exception, setException] = useState("");
	const [shouldShowScanner, setShouldShowScanner] = useState(false);
	const [scannerState, setScannerState] = useState(false);

	useEffect (() => {
		// need to rerender
	},[scannerState]);

	function handleSerialNumber (value:string) {
		if ( checkInput("text",serialNumberParm,value) ) {
			setSerialNumber(value);
		}
		else if ( value === "" ) {
			setSerialNumber("");
		}
	}
	const checkKeyPress = (e:any) => {
		const { key, keyCode } = e;
		if (keyCode === 13) {
			saveSerial({taskResult,meterDetails,onRequestClose,setMeterSelect, serialNumber, exception});
		}
	};

	function saveTheImage (image:Blob, serial_number:string) {
		saveImage(taskResult.client_id, taskResult.location, taskResult.employee_id, taskResult.suite, "Meter Scan", meterDetails.meter_id, taskResult.majorTaskNumber, serial_number, image);
	}
	return (<><div className="title">{Lang.updateSerialNumber}</div>
		<div>
			<div className="blockSection">
				<input
					name="newSerialNumber"
					placeholder={Lang.addSerialNumber}
					autoFocus
					value={serialNumber}
					onChange={(e) => {handleSerialNumber(e.target.value);}}
					onKeyDown={checkKeyPress}
				/>
				<div className="inblock pointer smallImage" onClick={() => {setScannerState(true);setShouldShowScanner(true);}}><div className="icon"><img src={camera} alt={Lang.cameraIcon}/></div></div>
			</div>
			<ModalFull shouldShow={shouldShowScanner} onRequestClose={() => {setScannerState(false);}}>
				<QRScanner setMeterSerial={(value:string) => {setSerialNumber(value);}} setScanState={scannerState} onRequestClose={() => {setShouldShowScanner(false);setScannerState(false);}} shouldSave={true} saveImage={(image:Blob,serial_number:string) => {saveTheImage(image,serial_number);}}/>
			</ModalFull>
			
			{ meterDetails.serial_number !== undefined &&  meterDetails.serial_number !== "" ? (
				<div className="blockSection">
					<div className="blockSection">
						{Lang.whyNewSerialNumber}
					</div>
					<input
						name="whyNewSerialNumber"
						placeholder={Lang.enterReason}
						value={exception}
						onChange={(e) => {handleException(e.target.value, setException);}}
						onKeyDown={checkKeyPress}
					/>
				</div>
			) : null}
			<ButtonComponent action={() => {saveSerial({taskResult,meterDetails,onRequestClose,setMeterSelect, serialNumber, exception });}} buttonText={Lang.saveSerialNumber}/>
		</div>
		
	</>);
};

async function saveSerial({taskResult,meterDetails,onRequestClose,setMeterSelect, serialNumber, exception}:any) {
	if ( serialNumber === undefined || serialNumber === "" ) {
		warning(Lang.serialNumberExpected);
		return;
	}
	if ( serialNumber === meterDetails.serial_number ) {
		warning(Lang.serialSameMessage);
		onRequestClose();
		return;
	}
	if ( (meterDetails.serial_number !== undefined &&  meterDetails.serial_number !== "" ) && ( exception === undefined || exception === "" ) ) {
		warning(Lang.exceptionExpected);
		return;
	}
	else if ( meterDetails.serial_number !== undefined && meterDetails.serial_number !== "" ) {
		recordException(taskResult.client_id, taskResult.location, taskResult.suite, taskResult.employee_id, Number(meterDetails.meter_id),"new_serial_number", meterDetails.serial_number, serialNumber, exception);
		warning("writing the exception",meterDetails.serial_number);
	}
	// save to meter
	const updated_at = new Date();
	await db.jobDetails.update(Number(meterDetails.meter_id),{serial_number: serialNumber, updated_at: updated_at});
	await db.meterDetails.update(Number(meterDetails.meter_id), {serial_number: serialNumber, updated_at: updated_at});
	const desc = checkForLang(meterDetails.device_type);
	const meterSelect = {"value": meterDetails.meter_id, 
		"label": desc+" - "+serialNumber};
	setMeterSelect(meterSelect);

	onRequestClose();
	return;
}

export { newMeter, getSuiteMeters, getSuiteMeter, MeterOverride, SerialNumber };