import { useEffect, useRef, useState } from "react";

import {lang} from "./appLang.js";
import {minutesToLogout, minutesToWarning} from "../controlVariables.js";
const Lang = lang();

export function TimeoutProvider({ loginState, setLoginState, setOpenId, resetEmployeeId, children }: any) {
	const timeoutRef = useRef<NodeJS.Timeout>();
	const ModalTimeoutRef = useRef<NodeJS.Timeout>();
	const [showTOWarning, setTOWarning] = useState(false);
	const notifyClass = "modalbody notification warning";
	
	useEffect(() => {

		const handleWindowEvents = () => {
			clearTimeout(timeoutRef.current);
			clearTimeout(ModalTimeoutRef.current);
			timeoutRef.current = setTimeout(() => {
				setTOWarning(true); // displays notification of expiring session
				ModalTimeoutRef.current = setTimeout(() => {
					setLoginState(false);  //remove login cred
					setOpenId();
					resetEmployeeId();
					setTOWarning(false);
					console.log("log out session");
				}, minutesToLogout*60000);
				console.log("timed out");
			}, minutesToWarning*60000);
		};

		// listen for specific window events to ensure user is still active
		window.addEventListener("mousemove", handleWindowEvents);
		window.addEventListener("keydown", handleWindowEvents);
		window.addEventListener("click", handleWindowEvents);
		window.addEventListener("scroll", handleWindowEvents);

		handleWindowEvents();

		//cleanup function
		return () => {
			window.removeEventListener("mousemove", handleWindowEvents);
			window.removeEventListener("keydown", handleWindowEvents);
			window.removeEventListener("click", handleWindowEvents);
			window.removeEventListener("scroll", handleWindowEvents);
		};
	}, [location.pathname]);
	return (
		<>
			
			{showTOWarning && (<div className='modalbackground' onClick={() => setTOWarning(false) }>
				<div className={notifyClass}>
					<p className="centre">{Lang.expiringSession}</p>
				</div>     
			</div>)}
			{children}</>)	;
}