
export const enlang = () =>  {
	return values;
};
// all language files must have the same pairs or they will not be shown

const appName = "OnSiteAssist";

const values = {
	"companyName": "Sagacity Software",
	"appName": appName,
	"navmenutitle": "Settings",
	"ChooseNewBuilding": "Select New Building",
	"editTimes": "Edit Times",
	"deleteDB": "Delete Browser Database",
	"navigate": "Settings",
	"newServiceWorker": "New version of the Sagacity app is available. Refresh now?",
	"expiringSession": "Your Session is about to expire",
	"updateStaticWorktask": "Update WorkTask Controls",
	"updateStaticInput": "Update Input Controls",
	"updateStaticProperties": "Update Properties",
	"updateStaticClients": "Update Client List",
	"updateStaticSuites": "Update Suites List",
	"updateAllTables": "Update All Tables",
	"tools": "App Tools",
	"errorStaticWorktask": "Problem Updating WorkTask Controls. Please update Manually",
	"errorStaticInput": "Problem Updating Input Controls. Please update Manually",
	"errorStaticProperties": "Problem Updating Properties. Please update Manually",
	"errorStaticClients": "Problem Updating Client List. Please update Manually",
	"errorStaticSuites": "Problem Updating Suites List. Please update Manually",
	"dbOptions": "Data Functions",
	"clockButton": "Task Tracking",
	"timeForTask": "Time Elapsed",
	"checkUpdates": "Checking for Updates",
	"finishCheckUpdates": "Completed Update Check",
	"termsAgreeStatement": "I agree to these Terms and Conditions",
	// error messages
	"failMessage": "Failed",
	"dbDeleteError": "Database not deleted",
	"dbDeleteBlock": "Database not deleted, still in use",
	"dbNotAuth": "Sagacity Tech App requires IndexedDB!",
	"updateError": "The table could not be updated, please try later",
	"geoBrowserSupport": "Geolocation is not supported by your browser",
	"geoGetFail": "Could not store the Geolocation",
	"onlyNumbers": "Only numbers are accepted",
	"onlyNumbersLetters": "Only numbers or letters are accepted",
	"between": "The input must be between",
	"and": "and",
	"completeTime": "Please enter a correct time",
	"oldTime": "The end time of the task is before the start time",
	"tooNewTime": "The end time cannot be in the future",
	"meterManditory": "A meter type must be selected",
	"suiteFound": "The suite has already been added",
	"oneElectrical": "Only one Electric meter per suite is allowed",
	"taskError": "The type of site visit and task to complete is required",
	"clientError": "A client company must be selected",
	"locationError": "A client location must be selected",
	"suiteError": "A suite / location must be selected",
	"inputLengthErrorA": "The input is longer than ",
	"inputLengthErrorB": " characters",
	"invalidInput": "Invalid input found, please retry",
	"exceptionExpectedGeneral": "Please check the Reason for the Update",
	"exceptionReason": "The Reason for the Change",
	"followSteps": "This Step can be Performed when the previous steps are Completed",
	"reasonPrefix": "Reason for the New ",
	"loginError": "User Name or Password is Incorrect",
	"passwordLengthError": "Password must be 8 characters",
	"mustBTUPipe": "A type of system must be chosen",
	"inputRequired": "Input is required",
	"saveError": "The Input was not saved, please retry",
	"noDetails": "Details must be provided",
	"mileageRequired": "Mileage must be Recorded",
	"noLoginWoConnection": "You must have a Internet Connection to Login",

	// confirmation messages
	"dbDelete": "Database was deleted",
	"successMessage": "Update Successful",
	"tableUpToDate": "No update required",
	// installation component
	"defectiveLabel": "Mark as Defective",
	"details": "Suite Meter Defaults",
	"changePropDetails": "Change Property Details",
	"suiteLabel": "Suite: ",
	"updateSerialNumber": "Update Serial Number",
	"meterNoteTitle": "Meter Notes",
	"deviceNoteTitle": "Device Notes",
	"addNote": "Add a Note",
	"editNote": "Edit the Note",
	"saveMeterNote": "Note Saved",
	"meterNoteDescription": "Add information about this meter.",
	"deviceNoteDescription": "Add information about this device.",
	"suiteNoteDescription": "Add information about this suite.",
	"defectiveNoteDescription": "Add all details of why you are marking defective.",
	"defectiveMessage": "Meter marked as Defective",
	"suiteNoteTitle": "Suite Notes",
	"locationNoteTitle": "Location Notes",
	"stepLabel": "Step #",
	"bypassButton": "Submit the ByPass Reason",
	"bypassRequestText": "Record Skip Reason",
	"bypassLabel": "Skipped ",
	"location": "Location",
	"devices": "Devices",
	"installTasks": "Install Tasks",

	// suite component
	"saveSuite": "Save Suite",
	"addNewSuite": "Add New Suite",
	"newSuite": "New Suite Number",
	// properties component
	"meterTypeLabel": "Installed Meter Types",
	"noSerialNumber": "No Serial",
	"newRecord": "To Be Started",
	"override": "Override Property Defaults",
	// meter component
	"overrideTitle": "Override Building Meter Default",
	"overrideMessage": "This function is used to override the building defaults (eg. two Fan Coil Units). Please populate the default meters first before adding the override meter",
	"serialNumberLabel": "Serial Number",
	"addSerialNumber": "Add Serial Number",
	"saveSerialNumber": "Save Serial Number",
	"whyNewSerialNumber": "Reason for New Serial Number",
	"enterReason": "Enter your Reason Here",
	"serialNumberExpected": "The Serial Number cannot be Blank",
	"serialSameMessage": "The Serial Number is the same, not updated",
	"exceptionExpected": "The Reason for the New Serial Number is Required",
	// form labels
	"loading": "Loading...",
	"taskPlaceholder": "Type of Site Visit",
	"noMinorTask": "Loading after Site Visit Selected",
	"taskMinorPlaceholder": "Task to Complete",
	"clientPlaceholder": "Client Company",
	"noLocationPlaceholder": "Loading after Client Selected",
	"locationPlaceholder": "Client Property",
	"noteplaceholder": "Add task note",
	"meternoteplaceholder": "Add a Meter Note",
	"devicenoteplaceholder": "Add a Device Note",
	"suitenoteplaceholder": "Add a Suite Note",
	"locationnoteplaceholder": "Add a Location Note",
	"defectivenoteplaceholder": "Add the details here",
	"mileagePlaceholder": "Mileage for Task (km)",
	"selectSuitePlaceholder": "Select a Suite",
	"selectMeterTypePlaceholder": "Select a Meter Type",
	"addNoteLabel": "Task Note",
	"noteLabel": "Current Task Note",
	"editNoteLabel": "Edit Note",
	"noteDescription": "Add information that is about the task. Job related information should be not be recorded here",
	
	"noOpenTask": "No Open Task for Today",
	"startTime": "Start Time",
	"endTime": "End Time",
	"openTask": "Current Task",
	"newTask": "New Task",
	"noNewTask": "No New Task",
	"am": "AM",
	"pm": "PM",
	"submit": "Submit",
	"mileage": "Mileage",
	"minutes": "minutes",
	"edit": "Edit",
	"add": "Add",
	"saveNote": "Task note saved",
	"saveMileage": "Mileage saved",
	"characterLeft": "Characters Left:",
	"electric": "Electric Meter",
	"gas": "Gas Meter",
	"water": "Water Meter",
	"hot_water": "Hot Water Meter",
	"btu": "BTU Meter",
	"twoPipe": "Two Pipe System",
	"fourPipe": "Four Pipe System",
	"electricOverride": "Electric Meter",
	"gasOverride": "Gas Meter",
	"waterOverride": "Water Meter",
	"hot_waterOverride": "Hot Water Meter",
	"btuOverride": "BTU Meter",
	"cameraIcon": "Enable Scanner",
	"saveNewTask": "Save New Task",
	"endCurrentTask": "End Current Task",
	"decimalWarning": "The value cannot have decimals",
	"decimalWarn1": "Only ",
	"decimalWarn2": " decimal places is permitted",


	// worklogtask labels
	"suiteComm": "Suite Commissioning",
	"Installation": "Device Installation",
	"Repair": "Meter Diagnosis and Repair",
	"Commissioning": "Commissioning",
	"Offsite": "Off Site Task",
	"SuiteInstall": "In Suite Meter Installation",
	"Replace": "Replace In Suite Device",
	"Lunch": "Lunch Break",
	"Break": "Special Break",
	"Travel": "Travel",
	"Delivery": "Delivery",
	"comms": "Gateway Communication",
	"commInstall": "Gateway Installation",
	"panelInstall": "Electrical Panel Installation",
	"replacegate": "Gateway Replacement",

	// inputcontrols labels
	"CT_number": "First CT Number",
	"connection_type": "Meter Connection Type",
	"meter_location":	"Meter Orientation",
	"CT_location_pic": "CT Location Picture",
	"current_read": "Current Meter Read",
	"final_install_picture": "Final Task Picture",
	"required": "Required: ",
	"recordedValue": "Saved Value:",
	"suggested": "Suggested Input: ",
	"outlet": "Return Side",
	"inlet": "Supply Side",
	"sweat": "Solider Connection",
	"thread": "Threaded Connection",
	
	
	// scanner and Camera labels
	"startScan": "Start the Scan",
	"stopScan": "Stop the Scan and Return",
	"retakePicture": "Retake Picture",
	"switchCamera": "Switch Camera",
	"takePhoto": "Take the Photo",
	"savePhoto": "Save the Photo",
	"oneCamera": "Device only has one camera.",
	"noCamera": "No Camera found",
	"noCameraMessage": "If this device has a camera, you can authorize usage to enable this feature.",
	"uploadPhotoSuccess": "The Photo has been Saved",
	"cameraTitle": appName+" Photo Capture",
	// tools
	"jobProgress": "Task Progress",
	"online": "Connected",
	"offline": "Disconnected",

	// login Page
	"welcomeLogin": "Welcome to "+appName,
	"loginButtonText": "LOGIN",
	"userName": "User Name",
	"password": "Password",
	"logoutText": "Log Out",
	"agree": "Submit Approval",
	"decline": "Decline these Terms",
	"confirmMessage": "Click OK to log out or Cancel to return to the form",

	// start page
	"workerLabel": "Worker Image",
	"amMessage": "Good Morning!",
	"pmMessage": "Good Afternoon!",
	"currentTasks": "Current Tasks",
	"completedTasks": "Completed Tasks",
	"startTask": "Start Job",
	"endTask": "End Job",
	"optionsTitle": appName+" Settings Options",
};

export default enlang;