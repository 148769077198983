import {db} from "../services/db";

function saveImage (client_id:number, property:number, employee_id:number, suite:string, name:string, meter_id:number, major_task:number, serial_number:string, image:Blob) {
	const stored_at = new Date();
	db.images.add({
		client_id: client_id,
		property_id: property,
		employee_id: employee_id,
		suite: suite,
		name: name,
		meter_id: meter_id,
		serial_number: serial_number,
		major_task: major_task,
		image: image,
		stored_at: stored_at,
	});
}

const GetImage = (client_id:number, property:number, suite:string, serial_number:string, meter_id:number, major_task:number, name:string, setStoredImage:any) => {
	setStoredImage();
	if ( meter_id !== undefined && serial_number !== undefined ) {
		db.images.where({client_id: client_id, property_id: property, suite: suite, meter_id: meter_id}).toArray().then((value:any) => {
			let image_date = "";
			let storedImage = "";
			for ( let i=0; i < value.length; i++ ) {
				if ( value[i].serial_number === serial_number && major_task === value[i].major_task && meter_id === value[i].meter_id && value[i].name === name ) {
					if ( storedImage === "" ) {
						image_date = value[i].stored_at;
						storedImage = value[i].image;
					}
					else {
						if ( value[i].stored_at > image_date ) {
							image_date = value[i].stored_at;
							storedImage = value[i].image;
						}
					}
				}
			}
			if ( storedImage !== "" ) {
				setStoredImage(storedImage);
				return;
			}
		});
	}
	return;
};

export {saveImage, GetImage};