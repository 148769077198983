import {lang} from "../components/appLang.js";
import { useState, useEffect } from "react";
import { trackTime } from "../controlVariables.js";
import { elapsedTime, checkForLang} from "../services/tools.js";

const Lang = lang();

function PageHeader({taskResult}:any) {
	const majorTaskDescription = checkForLang(taskResult.majorTask);
	let minorTask = undefined;
	if ( taskResult.minorTask ) {
		const minorParts = taskResult.minorTask.split(":");
		minorTask = minorParts[0];
	}
	const minorTaskDescription = checkForLang(minorTask);

	const [time, setTime] = useState<number>(0);
	const [timeElapsed, setTimeElapsed] = useState(0);
	
	useEffect(() => {
		setTime(Date.now());
		setTimeElapsed(elapsedTime(taskResult.start_time));
		const interval = setInterval(() => setTime(Date.now()), 30000);
		return () => {
			clearInterval(interval);
		};
	},[taskResult]);

	useEffect(() => {
		setTimeElapsed(elapsedTime(taskResult.start_time));
	},[time]);

	return <div className="page_header">
		<div className="nav">
			{taskResult.openId !== "0" ? (<>
				<div className="sectionInfo">{majorTaskDescription} - <div className="sectionInfoSmall inblock">{minorTaskDescription}</div></div>
				{trackTime ? (
					<div className="sectionInfoSmall">
						<b>{Lang.timeForTask}:</b> {timeElapsed} {Lang.minutes}
					</div>
				) : null} 
			</>
			) : <><h3>{Lang.noOpenTask}</h3></>}
		</div>
	</div>;

}


// function PageHeader({taskResult,setTaskResult,setTicketID,onlineStatus}:any) {
// 	const [shouldShowTime, setShouldShowTime] = useState(false);
// 	const majorTaskDescription:keyof typeof Lang = taskResult.majorTask;
// 	let minorTask = undefined;
// 	if ( taskResult.minorTask ) {
// 		const minorParts = taskResult.minorTask.split(":");
// 		minorTask = minorParts[0];
// 	}
// 	const minorTaskDescription:keyof typeof Lang = minorTask;

// 	const [time, setTime] = useState<number>(0);
// 	const [timeElapsed, setTimeElapsed] = useState(0);
	
// 	useEffect(() => {
// 		setTime(Date.now());
// 		setTimeElapsed(elapsedTime(taskResult.start_time));
// 		const interval = setInterval(() => setTime(Date.now()), 30000);
// 		return () => {
// 			clearInterval(interval);
// 		};
// 	},[taskResult]);

// 	useEffect(() => {
// 		setTimeElapsed(elapsedTime(taskResult.start_time));
// 	},[time]);

// 	return <div className="page_header">
// 		<div className="logo">
// 			<img src={logo} alt={Lang.companyName}/>
// 		</div>
// 		<div className="nav">
// 			<div className="status centre">
// 				{taskResult.openId !== "0" ? (<>
// 					<div className="sectionInfo">{Lang[majorTaskDescription]}</div>
// 					<div className="sectionInfoSmall indent"> - {Lang[minorTaskDescription]}</div>
// 					<div className="sectionInfoSmall"><b>{Lang.timeForTask}:</b> {timeElapsed} {Lang.minutes}</div>
// 				</>
// 				) : <><h3>{Lang.noOpenTask}</h3></>}
// 			</div>
// 			<div className="headerNav">
// 				<div className="hamburger centre pointer">
// 					<NavMenu>
// 						<p>{Lang.ChooseNewBuilding}</p>
// 						<p onClick={() => setShouldShowTime(!shouldShowTime)}>{Lang.clockButton}</p>
// 						<p>{Lang.dbOptions}</p>
// 						<ul>
// 							<li onClick={()=>{refreshStaticTable("worklogtasks");}}>{Lang.updateStaticWorktask}</li>
// 							<li onClick={()=>{refreshStaticTable("inputcontrols");}}>{Lang.updateStaticInput}</li>
// 							<li onClick={()=>{warning(Lang.loading);refreshStaticTable("property_details");refreshStaticTable("properties");}}>{Lang.updateStaticProperties}</li>
// 							<li onClick={()=>{refreshStaticTable("suites");}}>{Lang.updateStaticSuites}</li>
// 							<li onClick={()=>{refreshStaticTable("clients");}}>{Lang.updateStaticClients}</li>
// 							<li onClick={()=>{dbRemove("SagacityApp");}}>{Lang.deleteDB}</li>
// 						</ul>
					

// 					</NavMenu>
// 				</div>
// 				<div className="timeclock centre">
// 					<ButtonComponent action={() => setShouldShowTime(!shouldShowTime)} buttonText={Lang.clockButton} classname="greenbutton"></ButtonComponent>
// 					{onlineStatus ? (<>online</>) : (<>offline</>)}
// 				</div>
// 			</div>
// 			<Modal shouldShow={shouldShowTime} onRequestClose={() => setShouldShowTime(false)}>
// 				<TimeClock onRequestClose={() => setShouldShowTime(false)} taskResult={taskResult} setTaskResult={(value:any) => {setTaskResult(value);}} setTicketID={(value:number) => setTicketID(value)}/>
// 			</Modal>           
// 		</div>
// 	</div>;

// }
//<ButtonComponent action={() => setShouldShowTime1(!shouldShowTime1)} buttonText="End Time" classname="redbutton"></ButtonComponent>

export default PageHeader;

//