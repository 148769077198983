// api address

const apiURL = "https://demoapp.internalcity.ca/api";

// time tracking visable - 

const trackTime = true;

// used for the offsite values, turns off geolocation among other things, database id for worktasks
const offSiteId = 6; 
const lunchBreak = 7;
const specialBreak = 10;

//set in minutes for timeout of session
const minutesToWarning = 20; // these two values should represent the total time until the user has to login
const minutesToLogout = 1;

// set in minutes for geoLocation tracking while logged in
let geoRetrieveVar = 1;  

// number of minutes to check for Service Worker refresh
let swRefresh = .5; 
swRefresh = swRefresh*60*1000;

// set to true if more than one suite panel can be installed in a suite
const overrideElectral = false;

// serial number checks
const serialNumberParm = "alpha,20"; // options (number,min,max,decimal Places) - (alpha,number of chars)

// use of system disclaimers
const useDisclaimers = true;

// username length 
const userLength = 25;

// password length 
const passwordLength = 40;

export {offSiteId, geoRetrieveVar, overrideElectral, swRefresh, serialNumberParm, 
	useDisclaimers, userLength, passwordLength, lunchBreak, specialBreak, trackTime, 
	apiURL, minutesToLogout, minutesToWarning};

