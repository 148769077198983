import Select from "react-select";
import Async from "react-select/async";
import {useEffect, useState } from "react";
import {lang} from "../components/appLang.js";
import {db} from "../services/db";
import { error, success, warning } from "./Notifications";
import { getSuites, SuiteInput } from "./suite";
import { checkForLang, numberOnlyInput, verifyTime } from "../services/tools.js";
import { ButtonComponent, checkInput } from "./FormComponents";
import { offSiteId, lunchBreak, specialBreak, trackTime } from "../controlVariables.js";
import { Modal } from "./Modal";
import { successGeoCall } from "../services/geoLocate";
import { getStaticTable } from "./dbSections.jsx";


const Lang = lang();
let amValue = true;
let pmValue = false;

const TimeClock = ({onRequestClose,taskResult,setTaskResult,setTicketID, employee_id}:any) => {
	const defaultTimeText = "timeBox textBox";
	const defaultTimeDiv = "timeDiv";
	const [shouldShow, setShouldShow] = useState(false);
	const [majorTask, setMajorTask] = useState<any[]>([]);
	const [majorTaskMap, setMajorTaskMap] = useState<any[]>([]);
	const [majorTaskInd, setMajorTaskInd] = useState(0);
	const [majorTaskSelect, setMajorTaskSelect] = useState<any[]>([]);
	const [openMajor, setOpenMajor] = useState<string|number>("");
	const [openMajorNumber, setOpenMajorNumber] = useState(0);
	
	const [minorTaskMap, setMinorTaskMap] = useState<any[]>([]);
	const [minorTaskInd, setMinorTaskInd] = useState(0);
	const [minorTaskSelect, setMinorTaskSelect] = useState<any[]>([]);
	const [minorPlaceholder, setMinorPlaceholder] = useState(Lang.noMinorTask);
	const [openMinor, setOpenMinor] = useState<string|number>("");
	const [allMinorTasks, setAllMinorTasks] = useState<any[]>([]);
	
	const [openTask, setOpenTask] = useState<string|number>("0");
	const [openStart, setOpenStart] = useState<string|number>("");
	const [amValueCheck, setAmValueCheck] = useState(true);
	const [pmValueCheck, setPmValueCheck] = useState(false);
	
	const [clientProperties, setClientProperties] = useState<any[]>([]);
	const [clientProperty, setClientProperty] = useState(0);
	const [clientPropertiesMap, setClientPropertiesMap] = useState<any[]>([]);
	const [clientMap, setClientMap] = useState<any[]>([]);
	const [clientId, setClientId] = useState(0);
	const [clientSelect, setClientSelect] = useState<any[]>([]);

	const [locationPlaceholder, setLocationPlaceholder] = useState(Lang.noLocationPlaceholder);
	const [locationSelect, setLocationSelect] = useState<any[]>([]);
	const [settingLastDetails, setSettingLastDetails] = useState(true);

	const [suite, setSuite] = useState<string>("0");
	const [suiteSelect, setSuiteSelect] = useState<any>([]);
	const [suiteMap, setSuiteMap] = useState<any>([]);
	const [showSuiteModal, setShowSuiteModal] = useState<boolean>(false);
	const [suiteMeters, setSuiteMeters] = useState<any>([]);
	const [suiteMeterSelect, setSuiteMeterSelect] = useState([]);

	const [lastTaskDetails, setLastTaskDetails] = useState<string[]>([]);

	const currentDate = new Date();
	let currentHour = currentDate.getHours();
	const currentMinute = currentDate.getMinutes();
	
	const [taskNote, setTaskNote] = useState("");
	const [oldTaskNote, setOldTaskNote] = useState("");
	const [shouldShowNote, setShouldShowNote] = useState(false);
	const [noteLabel, setNoteLabel] = useState(Lang.addNoteLabel);
	const [editLabel, setEditLabel] = useState(" - "+Lang.noteplaceholder);
	const [taskNoteLength, setTaskNoteLength] = useState(0);
	const [showNewTask, setShowNewTask] = useState(true);
	
	const [shouldShowMileage, setShouldShowMileage] = useState(false);
	const [taskMileage, setTaskMileage] = useState<number|undefined>();
	const [oldMileage, setOldMileage] = useState<number|undefined>();
	const [mileageLabel, setMileageLabel] = useState(" - "+Lang.add);

	const [taskHour, setTaskHour] = useState<number>(currentHour);
	const [classTaskHour, setClassTaskHour] = useState<string>(defaultTimeText);
	const [taskMinute, setTaskMinute] = useState<number>(currentMinute);
	const [classTaskMinute, setClassTaskMinute] = useState<string>(defaultTimeText);
	const [errorHighlight, setErrorHighlight] = useState<string>(defaultTimeDiv);

	useEffect(() => {  // get the dropdown task options
		console.log("in first render useEffect - timeClock"+employee_id);
		getOptions(employee_id).
			then((allTasks:any[]) => {
				setMajorTask(allTasks[0]);
				setAllMinorTasks(allTasks[1]);
				setMajorTaskMap(allTasks[2]);
				const lastDetail = localStorage.getItem("lastTaskDetails");
				if ( lastDetail !== null && lastDetail !== "" ) {
					const lastDetails = lastDetail.split(",");
					setLastTaskDetails(lastDetails);
				}
			});
		
		getClients(employee_id).
			then((result:any) => {
				setClientMap(result);
				if ( result.length === 1 ) {
					setClientId(result[0].value);
				}
			});

		getProperites(employee_id).
			then((result:any) => {
				setClientPropertiesMap(result);
			});
		
		if ( currentDate.getHours() > 11 ) {
			pmValue = true;
			amValue = false;
			setAmValueCheck(false);
			setPmValueCheck(true);
			if ( currentDate.getHours() > 12 ) {
				currentHour = (currentDate.getHours()-12);
				setTaskHour(currentHour);
			}
		}
		if ( currentDate.getHours() === 0 ) {
			currentHour = 12;
			setTaskHour(currentHour);
		}
		console.log(taskResult);
	},[]);

	useEffect(() => {
		if ( settingLastDetails ) {
			for ( let i=0;i<majorTaskMap.length;i++ ) {  // set major number loop
				if ( Number(lastTaskDetails[0]) === majorTaskMap[i].value ) {
					setMajorTaskSelect(majorTaskMap[i]);
					setMajorTaskInd(Number(lastTaskDetails[0]));
					i = majorTaskMap.length;
					for ( let j=0;j<minorTaskMap.length;j++ ) { // set minor number loop
						if ( Number(lastTaskDetails[1]) === minorTaskMap[j].value ) {
							setMinorTaskSelect(minorTaskMap[j]);
							setMinorTaskInd(Number(lastTaskDetails[1]));
							j = minorTaskMap.length;
						}
					}
				}
			}
			for ( let i=0;i<clientMap.length;i++ ) { // set client loop
				if ( Number(lastTaskDetails[2]) === clientMap[i].value ) {
					setClientId(Number(lastTaskDetails[2]));
					setClientSelect(clientMap[i]);
					i = clientMap.length;
				}
			}
		}
		
	},[lastTaskDetails,clientId,minorTaskMap,clientMap]);

	useEffect(() => {
		if ( taskResult.openId !== "0" ) {
			setOpenTask(taskResult.openId);
			let value = checkForLang(taskResult.majorTask);
			setOpenMajor(value);
			if ( taskResult.minorTask !== undefined ) {
				value = taskResult.minorTask.replace(/:.*/,"");
				setOpenMinor(value);
			}
			setTaskNote(taskResult.note);
			setOldTaskNote(taskResult.note);
			setTaskMileage(taskResult.mileage);
			setOldMileage(taskResult.mileage);
			setOpenStart(taskResult.start_time);
			setOpenMajorNumber(taskResult.majorTaskNumber);
			setTaskResult(taskResult);
		}
	},[taskResult]);

	useEffect(() => {
		if ( clientId === 0 ) {
			setClientProperties([]);
			setClientSelect([]);
		}
		else {
			const returnList = [];
			for ( let i =0; i< clientPropertiesMap.length; i++ ) {
				if ( clientPropertiesMap[i].clientId === clientId ) {
					returnList.push({
						"value": clientPropertiesMap[i].value, 
						"label": clientPropertiesMap[i].label
					});
				}
			}
			setLocationPlaceholder(Lang.locationPlaceholder);
			setClientProperties(returnList);
		}
		
	},[clientId,clientPropertiesMap]);

	useEffect(() => {
		if ( settingLastDetails ) {
			for ( let j=0;j<clientProperties.length;j++ ) { // set property loop
				if ( Number(lastTaskDetails[3]) === clientProperties[j].value ) {
					setClientProperty(Number(lastTaskDetails[3]));
					setLocationSelect(clientProperties[j]);
					j=clientProperties.length;
				}
			}
		}
	},[clientProperties]);

	useEffect(() => { // set the minor task dropdown
		if ( majorTaskInd === 0 ) {
			setMinorTaskMap([]);
			setMinorTaskInd(0);
			setMinorPlaceholder(Lang.noMinorTask);
		}
		else {
			setMinorTaskInd(0);
			setMinorPlaceholder(Lang.taskMinorPlaceholder);
			const minorListMap = [];
			let taskParts:any[];
			for (let i = 0; i < allMinorTasks.length; i++) {
				taskParts = allMinorTasks[i].short_description.split(":");
				if ( ( taskParts.length === 1 && majorTaskInd !== offSiteId ) || Number(taskParts[1]) === majorTaskInd ) {
					const desc = checkForLang(taskParts[0]);
					minorListMap.push({
						"value": allMinorTasks[i].id,
						"label": desc
					});
				}
			}
			setMinorTaskMap(minorListMap);
			
		}
	},[majorTaskInd]);

	useEffect(() => {
		if ( taskNote.length > 0 ) {
			setNoteLabel(Lang.noteLabel);
			setEditLabel(" - "+Lang.editNoteLabel);
			if ( taskNote.length > 250 ) {
				warning(Lang.inputLengthErrorA+"250"+Lang.inputLengthErrorB);
			}
			setTaskNote(taskNote.substring(0,250));
			setTaskNoteLength(taskNote.length);
		}
		else {
			setNoteLabel(Lang.addNoteLabel);
			setEditLabel(" - "+Lang.noteplaceholder);
			setTaskNoteLength(0);
		}
	}),[taskNote];

	useEffect(() => {
		getSuites(clientId,clientProperty).then((result): any => {
			setSuiteMap(result);
			if ( settingLastDetails ) {
				if ( result !== undefined ) {
					for ( let t=0;t<result.length;t++ ) { // set suite loop
						const suite = result[t].value.split("-");
						if ( suite.length > 3 ) {
							suite[2] = suite.splice(2).join("-");
						}
						if ( lastTaskDetails[4] === suite[2] ) {
							setSuite(lastTaskDetails[4]);
							setSuiteSelect(result[t]);
						}
					}
				}
			}
		});
		if ( suiteSelect ) {
			setSuite(suiteSelect.label);
		}
		else {
			setSuite("0");
		}
	},[clientProperty]);

	function checkMileageInput (value:string) {
		const testedValue = numberOnlyInput(value);
		setTaskMileage(testedValue);
		if ( testedValue === "" || testedValue === "0" || testedValue === null || testedValue === undefined ) {
			setMileageLabel(" - "+Lang.add);
		}
		else {
			setMileageLabel(" - "+Lang.edit);
		}
	}

	function checkHourInput (value:any) {
		const testedValue = numberOnlyInput(value, 1, 12);
		setTaskHour(value);
		if ( value !== testedValue ) {
			setTaskHour(testedValue);
			setClassTaskHour(classTaskHour+" verror");
		}
		else {
			setClassTaskHour(defaultTimeText);
		}
	}

	function checkMinuteInput (value:any) {
		const testedValue = numberOnlyInput(value, 0, 59);
		setTaskMinute(value);
		if ( value !== testedValue ) {
			setTaskMinute(testedValue);
			setClassTaskMinute(classTaskMinute+" verror");
		}
		else {
			setClassTaskMinute(defaultTimeText);
		}
	}

	function clickAM () {
		amValue = true;
		pmValue = false;
		setAmValueCheck(true);
		setPmValueCheck(false);
	}

	function clickPM () {
		amValue = false;
		pmValue = true;
		setAmValueCheck(false);
		setPmValueCheck(true);
	}

	function checkTime (checkType = "passive") {
		let ampm = Lang.am;
		if ( pmValue ) {
			ampm = Lang.pm;
		}
		const timeCheck = verifyTime(openStart,taskHour,taskMinute,ampm,checkType);
		if ( timeCheck !== "ok" ) {
			setErrorHighlight(defaultTimeDiv+" verror");
			if ( timeCheck === "badEnd" && trackTime ) {
				error(Lang.oldTime);
			}
			else if ( timeCheck === "badStart" && trackTime ) {
				error(Lang.tooNewTime);
			}
		}
		else {
			setErrorHighlight(defaultTimeDiv);
		}
		return timeCheck;
	}

	function filterProperties(inputValue:string) {
		return clientProperties.filter((input) => {
			return input.label.toLowerCase().includes(inputValue.toLowerCase());
		});
	}

	function handleMajor(event:any) {
		if ( settingLastDetails && offSiteId === event.value ) {
			if ( clientMap.length > 1 ) {
				setClientId(0);
				setLocationPlaceholder(Lang.noLocationPlaceholder);
			}
			else {
				setLocationPlaceholder(Lang.locationPlaceholder);
			}
			setLocationSelect([]);
			setClientProperty(0);
			setSuiteMap([]);
			setSuiteSelect([]);
		}
		setSettingLastDetails(false);
		setMajorTaskSelect(event);
		if ( event !== null ) {
			setMajorTaskInd(event.value);
		}
		else {
			setMajorTaskInd(0);
		}
		setMinorTaskInd(0);
		setMinorTaskSelect([]);
	}

	function handleMinor(event:any) {
		setSettingLastDetails(false);
		setMinorTaskSelect(event);
		if ( event !== null ) {
			setMinorTaskInd(event.value);
		}
		else {
			setMinorTaskInd(0);
		}
	}

	function handleClient(event: any) {
		setSettingLastDetails(false);
		setClientSelect(event);
		if ( event !== null ) {
			setClientId(event.value);
		}
		else {
			setClientId(0);
		}
		setLocationSelect([]);
		setLocationPlaceholder(Lang.noLocationPlaceholder);
		setClientProperty(0);
		setSuiteMap([]);
		setSuiteSelect([]);
	}

	function handleLocation(event: any) {
		setSettingLastDetails(false);
		setLocationSelect(event);
		if ( event !== null ) {
			setClientProperty(event.value);
		}
		else {
			setClientProperty(0);
		}
		setSuiteMap([]);
		setSuiteSelect([]);
	}

	async function saveNoteInfo(note:string) {
		const updated_at = new Date().toISOString();
		await db.workLog.update(Number(openTask), {note: note, updated_at: updated_at});
		taskResult.note=note;
		setOldTaskNote(note);
		success(Lang.saveNote);
	}

	async function saveMileage(miles:number|undefined) {
		const updated_at = new Date().toISOString();
		if ( miles === 0 || miles === undefined ) {
			setMileageLabel(" - "+Lang.add);
		}
		if ( !checkInput("text","number,0,9999999999",miles) ) {
			setTaskMileage(oldMileage);
			return;
		}
		await db.workLog.update(Number(openTask), {mileage: miles, updated_at: updated_at});
		setOldMileage(miles);
		taskResult.mileage=miles;
		success(Lang.saveMileage);
		setShouldShowMileage(false);
	}

	function handleSuite(event:any) {
		setSettingLastDetails(false);
		setSuiteSelect(event);
		if ( event !== null ) {
			const suite = event.value.split("-");
			if ( suite.length > 3 ) {
				suite[2] = suite.splice(2).join("-");
			}
			setSuite(suite[2]);
		}
		else {
			setSuite("0");
		}
	}
	
	async function saveTicketInfo () {
		const timeCheck = checkTime("submit");
		if (timeCheck !== "ok" && trackTime ) {
			return;
		}
		const updated_at = new Date().toISOString();
		const todayDate = new Date();
		let ampm = Lang.am;
		if ( pmValue ) {
			ampm = Lang.pm;
		}
		if ( taskResult.majorTaskNumber === offSiteId && ( taskResult.minorTaskNumber !== lunchBreak && taskResult.minorTaskNumber !== specialBreak ) ) {
			if ( oldMileage === 0 ) {
				warning(Lang.mileageRequired);
				return;
			}
		}
		if ( !showNewTask ) {
			if ( openTask !== 0 ) {
				await db.workLog.update(Number(openTask), {updated_at: updated_at, end_time: taskHour+":"+taskMinute.toString().padStart(2,"0")+" "+ampm});
				navigator.geolocation.getCurrentPosition((position) => {
					successGeoCall(position,openTask);
				});
			}
			setTicketID(0);
			localStorage.setItem("currentWorklog",0+","+updated_at);	
			onRequestClose();
			return;
		}
		if ( Number(majorTaskInd) === 0 || Number(minorTaskInd) === 0 ) {
			error(Lang.taskError);
			return;
		}
		if ( Number(majorTaskInd) != offSiteId ) {
			if ( clientId === 0 ) {
				error(Lang.clientError);
				return;
			}
			if ( clientProperty === 0 ) {
				error(Lang.locationError);
				return;
			}
			if ( suite === "0" || suite === undefined ) {
				error(Lang.suiteError);
				return;
			}
		}
		if ( openTask !== 0 ) {
			await db.workLog.update(Number(openTask), {updated_at: updated_at, end_time: taskHour+":"+taskMinute.toString().padStart(2,"0")+" "+ampm});
			navigator.geolocation.getCurrentPosition((position) => {
				successGeoCall(position,openTask);
			});
		}
		try {
			await db.workLog.add({
				major_task: Number(majorTaskInd),
				minor_task: Number(minorTaskInd),
				note: "",
				updated_at: updated_at,
				start_time: taskHour+":"+taskMinute.toString().padStart(2,"0")+" "+ampm,
				end_time: "",
				date: todayDate,
				employee_id: employee_id,
				mileage: 0,
				location: clientProperty,
				client_id: clientId,
				suite_id: suite,
				api_worklog_id: 0
			}).then(async (id) => {
				localStorage.setItem("currentWorklog",Number(id)+","+updated_at);	
				await navigator.geolocation.getCurrentPosition(async (position) => {
					await successGeoCall(position);
				});
				setTicketID(Number(id));
				if ( majorTaskInd !== offSiteId ) {
					const lastTaskDetails = [majorTaskInd,minorTaskInd,clientId,clientProperty,suite];
					localStorage.setItem("lastTaskDetails",lastTaskDetails.join(","));
				}
				else if ( minorTaskInd !== lunchBreak && minorTaskInd !== specialBreak ) {
					const lastTaskDetails = [0,0,clientId,clientProperty];
					localStorage.setItem("lastTaskDetails",lastTaskDetails.join(","));
				}
				
			});
			onRequestClose();
		}
		catch (error) {
			console.log(error);
		}
	}

	return majorTask !== undefined ? (<>
		{openTask !== "0" ? (<><h3>{Lang.openTask}</h3>
			<div className="section">
				<div className="subSection">
					{ trackTime ? (<div className="sectionInfo">{Lang.startTime} {openStart}</div>) : null}
					<div className="sectionInfo">{openMajor}</div>
					<div className="sectionInfoSmall indent">{openMinor}</div>
				</div>
				<div className="subSection"><div className="sectionInfo pointer" onClick={() => setShouldShowNote(!shouldShowNote)}>{noteLabel}<div className="smallText link">{editLabel}</div></div>
					{taskNote !== "" ? (<div className="sectionInfoSmall indent">{taskNote}</div>) : null}
					<Modal shouldShow={shouldShowNote} onRequestClose={() => {setTaskNote(oldTaskNote);setShouldShowNote(false);}}>
						{Lang.noteDescription}
						<textarea 
							rows={4}
							wrap="hard"
							name="taskNote"
							autoFocus
							value={taskNote}
							className="taskNotepad"
							placeholder={Lang.noteplaceholder}
							onChange={(e) => setTaskNote(e.target.value)}
						/>
						<br></br>
						<ButtonComponent action={() => {saveNoteInfo(taskNote);setShouldShowNote(false);}} buttonText={Lang.submit}/>
						<div className="sectionInfoSmall subSection">{Lang.characterLeft} {250-taskNoteLength}</div>
					</Modal>
					{ openMajorNumber === offSiteId ? (<><div  className="pointer" onClick={() => setShouldShowMileage(!shouldShowMileage)}>{Lang.mileage}<div className="smallText">{mileageLabel}</div>
						<div className="sectionInfoSmall indent"> {taskMileage}</div>
					</div>
					<Modal shouldShow={shouldShowMileage} onRequestClose={() => {checkMileageInput(String(oldMileage));setShouldShowMileage(false);}}>
						<input 
							name="taskMileage"
							placeholder={Lang.mileagePlaceholder}
							autoFocus
							value={taskMileage}
							inputMode="numeric"
							onChange={(e) => checkMileageInput(e.target.value)}
						/>
						<ButtonComponent action={() => {saveMileage(taskMileage);}} buttonText={Lang.submit}/>
					</Modal></>) : null }
				</div>
					
			</div>
			{ trackTime ? (<h3>{Lang.endTime}/{Lang.startTime}</h3>) : null} </>
		) : <><h3>{Lang.noOpenTask}</h3><br></br>{ trackTime ? (<h3>{Lang.startTime}</h3>) : null}</>}
		{ trackTime ? (<>
			<div className="section">
				<div className={errorHighlight}>
					<input 
						onChange={(e) => {checkHourInput(e.target.value);}} 
						onBlur={() => {checkTime();}}
						className={classTaskHour} 
						inputMode="numeric" 
						maxLength={2} 
						name="taskHour" 
						value={taskHour} 
						placeholder="HH" 
						size={1}
					/>
					<div className="timeSeparator">:</div>
					<input 
						onChange={(e) => {checkMinuteInput(e.target.value);}}
						onBlur={() => checkTime()}
						className={classTaskMinute} 
						inputMode="numeric" 
						maxLength={2} 
						name="taskMinute" 
						value={taskMinute} 
						placeholder="MM" 
						size={1}/>
					<div className="radioButtons"><input 
						name="ambutton"
						type="radio"
						onClick={() => {clickAM();}}
						onChange={() => {checkTime("verify");}}
						className="radio"
						value="am"
						checked={amValueCheck}/></div>{Lang.am}
					<div className="radioButtons"><input 
						name="pmbutton"
						type="radio"
						onClick={() => {clickPM();}}
						onChange={() => {checkTime("verify");}}
						className="radio"
						value="pm"
						checked={pmValueCheck}/>
					</div>{Lang.pm}
				</div>
			</div>
		</>) : null} 
		{ showNewTask ? (<><h3>{Lang.newTask}
			{ openTask !== "0" ? (<div className="floatRight pointer link" onClick={() => {setShowNewTask(false);}}>
				{Lang.noNewTask}
			</div>) : null}
		</h3>
		<div className="section">
			<div className="taskSection">
				<Select 
					options={majorTaskMap}
					className="react-select-container"
					classNamePrefix="react-select"
					unstyled
					placeholder={Lang.taskPlaceholder}
					isClearable={true}
					value={majorTaskSelect}
					onChange={handleMajor}
					maxMenuHeight={100}
				/>
				<Select 
					options={minorTaskMap}
					className="react-select-container"
					classNamePrefix="react-select"
					unstyled
					placeholder={minorPlaceholder}
					isClearable={true}
					value={minorTaskSelect}
					onChange={handleMinor}
					maxMenuHeight={100}
				/>
				{ offSiteId === majorTaskInd && (lunchBreak === minorTaskInd || specialBreak === minorTaskInd ) ? null 
					: (<>
						{ clientMap.length !== 1 ? (
							<Select
								options={clientMap}
								className="react-select-container"
								classNamePrefix="react-select"
								unstyled
								placeholder={Lang.clientPlaceholder}
								isClearable={true}
								value={clientSelect}
								onChange={handleClient}
								maxMenuHeight={100}
							/>
						) : null }
				
						<Async
							cacheOptions 
							defaultOptions={clientProperties}
							className="react-select-container"
							classNamePrefix="react-select"
							unstyled
							placeholder={locationPlaceholder}
							isClearable={true}
							value={locationSelect}
							loadOptions={(inputValue:string, callback: (options: any) => void) =>
							{setTimeout(() => callback(filterProperties(inputValue)), 1000);}
							}
							onChange={handleLocation}
							maxMenuHeight={100}
						/>
						{  offSiteId !== majorTaskInd ? (
							<div className="blockSection">
								<div className="threeQuarter inblock">
									<Select
										options={suiteMap}
										value={suiteSelect}
										className="react-select-container"
										classNamePrefix="react-select"
										unstyled
										placeholder={Lang.selectSuitePlaceholder}
										isClearable={true}
										onChange={handleSuite}
										maxMenuHeight={100}
									/>
								</div>
								{ clientProperty !== 0 ? (<div className="details pointer beside link" onClick={() => {setSettingLastDetails(false);setShouldShow(true);}}>{Lang.addNewSuite}</div>) : null }
								<Modal shouldShow={shouldShow} onRequestClose={() => setShouldShow(false)}> 
									<SuiteInput 
										clientId={clientId} 
										clientProperty={clientProperty} 
										locationSelect={locationSelect} 
										setShouldShow={() => setShouldShow(false)} 
										setSuiteSelect={(value:any) => setSuiteSelect(value)} 
										setSuite={(value:any) => setSuite(value)}
										setSuiteMap={(value:any) => {const newMap = suiteMap; newMap.push(value);setSuiteMap(newMap);}}
									/>
								</Modal>	
							</div>
						) : null}
						
					</>)

				}
				
			</div>
			<div className="blockSection">
				<ButtonComponent action={() => {saveTicketInfo();}} buttonText={Lang.saveNewTask}/>
			</div>
		</div>
		</>) : (<div className="blockSection">
			<ButtonComponent action={() => {saveTicketInfo();}} buttonText={Lang.endCurrentTask}/>
		</div>) }
	</>) : <p>{Lang.loading}</p>;
	
};

export {TimeClock} ;

async function getOptions (employee_id: number) {
	let allList:any = await db.worklogtasks.toArray();
	if ( allList.length === 0 ) {
		await getStaticTable("worklogtasks",employee_id).then(async () => {
			allList = await db.worklogtasks.toArray();
		});
		
	}
	const majorList = [];
	const minorList = [];
	const majorListMap = [];
	for (let i = 0; i < allList.length; i++) {
		if ( allList[i].majortask ) {
			majorList.push(allList[i]);
			const desc = checkForLang(allList[i].short_description);
			majorListMap.push({
				"value": allList[i].id,
				"label": desc
			});
		}
		else if ( allList[i].minortask ) {
			minorList.push(allList[i]);
		}
	}
	return [majorList,minorList,majorListMap];
}

async function getProperites (employee_id: number) {
	let properties = await db.properties.toArray();
	if ( properties.length === 0 ) {
		await getStaticTable("properties",employee_id).then(async () => {
			properties = await db.properties.toArray();
		});
		
	}
	const returnList = [];
	for ( let i =0; i< properties.length; i++ ) {
		returnList.push({
			"value": properties[i].id, 
			"label": properties[i].street1+", "+properties[i].city,
			"clientId": properties[i].client_id
		});
	}
	return returnList;
}

async function getClients (employee_id: number) {
	let clients = await db.clients.toArray();
	if ( clients.length === 0 ) {
		await getStaticTable("clients",employee_id).then(async () => {
			clients = await db.clients.toArray();
		});
		
	}
	const returnList = [];
	for ( let i =0; i< clients.length; i++ ) {
		returnList.push({
			"value": clients[i].client_id, 
			"label": clients[i].name
		});
	}
	return returnList;
}



