// db.ts
import Dexie, { Table } from "dexie";

const versionNumber = 14;

export interface geoLocation {
	id?: number;
	lat: number;
	lon: number;
	alt: number;
	worklog_id: number;
	timestamp: string;
}

export interface workLog {
	id?: number;
	api_worklog_id: number;
	start_time: string;
	end_time: string;
	date: Date;
	updated_at: string;
	employee_id: number;
	major_task: number;
	minor_task: number;
	note: string;
	mileage: number;
	location: number;
	client_id: number;
	suite_id: string;
}

export interface worklogDetails {
	id?: number;
	worklog_id: number;
	meter_id: number;
	started_at: Date;
}
export interface inputcontrols {
	id: number;
	form_name: string;
	field: string;
	field_type: string;
	restriction: string;
	active: boolean;
	mandatory: boolean;
	hold: boolean;
	updated_at: string;
}

export interface worklogtasks {
	id: number;
	majortask: boolean;
	minortask: boolean;
	short_description: string;
	updated_at: string;
}

export interface properties {
	id: number;
	client_id: number;
	street1: string;
	city: string;
	details: object;
	updated_at: string;
}

export interface property_details {
	id: number;
	client_id: number;
	meter_electric: boolean;
	meter_gas: boolean;
	meter_water: boolean;
	meter_water_hot: boolean;
	meter_btu: boolean;
	pipe_system: number;
	updated_at: string;
}

export interface suites {
	id: number;
	suite_key: string;
	location_id: number;
	client_id: number;
	suite: string;
	updated_at: string;
	note: string;
}

export interface clients {
	client_id: number;
	name: string;
	updated_at: string;
}

export interface meterDetails {
	meter_id?: number;
	api_meter_id: number;
	client_id: number;
	property_id: number;
	date: Date;
	suite: string;
	device_type: string;
	serial_number: string;
	details: string;
	note: string;
	updated_at: Date;
	defective: boolean;
}

export interface exceptionDetails {
	id: number;
	oldValue: string;
	newValue: string;
	updated_at: Date;
	property_id: number;
	client_id: number;
	meter_id: number;
	suite: string;
	employee_id: number;
	exceptionType: string;
	note: string;
}

export interface images {
	id?: number;
	client_id: number;
	property_id: number;
	employee_id: number;
	suite: string;
	meter_id: number;
	serial_number: string;
	major_task: number;
	name: string;
	image: Blob;
	stored_at: Date;
}

interface jobDetailsObj {
	field: string;
	status: boolean;
	value: string;
}

type objArray = jobDetailsObj[];
export interface jobDetails {
	id?: number;
	client_id: number;
	property_id: number;
	suite: string;
	serial_number: string;
	meter_id: number;
	updated_at: Date;
	details: objArray;
	job_type: string;
}

export interface worklogAudit {
	id?: number;
	worklogDetails_id: number;
	field: string;
	entered_at: Date;
	value: string;
}

export interface testTable {
	id?: number;
	updated: Date;
}

export interface systemControls {
	id?: number;
	name: string;
	value: string;
	employee_id: number;
	consent: boolean;
	update: Date;
}

export class MySubClassedDexie extends Dexie {
	geoLocation!: Table<geoLocation>;
	workLog!: Table<workLog>;
	inputcontrols!: Table<inputcontrols>;
	worklogtasks!: Table<worklogtasks>;
	properties!: Table<properties>;
	property_details!: Table<property_details>;
	clients!: Table<clients>;
	meterDetails!: Table<meterDetails>;
	suites!: Table<suites>;
	exceptionDetails!: Table<exceptionDetails>;
	images!: Table<images>;
	worklogDetails!: Table<worklogDetails>;
	jobDetails!: Table<jobDetails>;
	worklogAudit!: Table<worklogAudit>;
	testTable!: Table<testTable>;
	systemControls!: Table<systemControls>;
	
	constructor() {
		super("SagacityApp");
		this.version(versionNumber).stores({
			geoLocation: "++id, worklog_id, timestamp",
			workLog: "++id, date, start_time, [employee_id+date]",
			worklogDetails: "++id",
			suites: "suite_key, [client_id+location_id], [client_id+location_id+suite]",
			inputcontrols: "id, form_name, [form_name+position]",
			worklogtasks: "id",
			properties: "id, [client_id+street1], [client_id+id]",
			property_details: "id, [client_id+id]",
			clients: "client_id",
			meterDetails: "++meter_id, [client_id+property_id], [client_id+property_id+suite], [client_id+property_id+suite+job_type]",
			exceptionDetails: "++id",
			images: "++id, [client_id+property_id], [client_id+property_id+suite], [client_id+property_id+suite+meter_id]",
			jobDetails: "++id, meter_id, [client_id+property_id+job_type+meter_id]",
			worklogAudit: "++id",
			testTable: "++id",
			systemControls: "++id, name, [name+employee_id]",
		});
	}
}

export const db = new MySubClassedDexie();