import { useEffect } from "react";
import {db} from "../services/db";
import { useState } from "react";
import {lang} from "./appLang.js";
import { ButtonComponent, checkInput } from "./FormComponents";
import { warning, error } from "./Notifications.js";

const Lang = lang();

async function getSuites (clientId:number, location:number) {
	const suites = await db.suites.where({client_id: clientId,location_id: location}).toArray();
	const returnList = [];
	if ( suites ) {
		for ( let i=0; i< suites.length; i++ ) {
			returnList.push({
				"value": suites[i].suite_key, "label": suites[i].suite
			});
		}
		return returnList;
	}
}

const SuiteInput = ({clientId, clientProperty, locationSelect, setShouldShow, setSuiteSelect, setSuite, setSuiteMap}:any ) => {
	const [suiteNumber, setSuiteNumber] = useState("");
	
	const checkKeyPress = (e:any) => {
		const { key, keyCode } = e;
		if (keyCode === 13) {
			saveSuites(clientId, clientProperty,suiteNumber, setSuiteSelect, setSuite, setSuiteMap, setShouldShow);
		}
	};

	const checkSuiteInput = (value:string) => {
		if ( value === undefined || value === "" ) {
			return;
		}
		if ( !checkInput("text","all,25",value) ) {
			return;
		}
		setSuiteNumber(value);
	};

	return (<><div className="title">{locationSelect.label}</div>
		<div>
			<input
				name="newSuiteNumber"
				placeholder={Lang.newSuite}
				autoFocus
				value={suiteNumber}
				onChange={(e) => {checkSuiteInput(e.target.value);}}
				onKeyDown={checkKeyPress}
			/>
			<ButtonComponent action={() => {saveSuites(clientId, clientProperty,suiteNumber, setSuiteSelect, setSuite, setSuiteMap, setShouldShow);}} buttonText={Lang.saveSuite}/>
		</div>
		
	</>);
};

async function checkSuite (clientId:number, clientProperty:number, suiteInput: any, setSuiteSelect: any) {
	console.log(clientId+"-"+clientProperty+"-"+suiteInput);
	let output;
	await db.suites.where({client_id: clientId,location_id: clientProperty,suite:suiteInput}).toArray().then((id: any) => {
		if ( id.length !== 0 ) {
			setSuiteSelect({"value": clientId+"-"+clientProperty+"-"+suiteInput, "label": suiteInput});
			output = id[0].suite_key;
		}
	});
	return output;
}

async function getSuiteNote (clientId:number, clientProperty:number, suiteInput: string) {
	let output = "";
	await db.suites.where({client_id: clientId,location_id: clientProperty,suite:suiteInput}).toArray().then((id: any) => {
		if ( id !== undefined && id.length !== 0 ) {
			output = id[0].note;
		}
	});
	return output;
}

async function saveSuites (clientId:number, clientProperty:number, suiteInput: any, setSuiteSelect: any, setSuite: any, setSuiteMap: any, setShouldShow: any) {
	if ( suiteInput === undefined || suiteInput === "" ) {
		warning(Lang.inputRequired);
		return;
	}
	const todayDate = new Date().toISOString();
	const output = await checkSuite(clientId, clientProperty,suiteInput,setSuiteSelect);
	if ( output !== undefined ) {
		warning(Lang.suiteFound);
		return;
	}
	else {
		
		try {
			db.suites.add({
				id: 0,
				client_id: clientId,
				location_id: clientProperty,
				suite: suiteInput,
				updated_at: todayDate,
				suite_key: clientId+"-"+clientProperty+"-"+suiteInput,
				note: ""
			}).then(() => {
				const selectValue = {"value": clientId+"-"+clientProperty+"-"+suiteInput, "label": suiteInput};
				setSuiteSelect(selectValue);
				setSuiteMap(selectValue);
			});
		}
		catch (err) {
			console.log(err);
			error(Lang.saveError);
			return;
		}
	}
	setSuite(suiteInput);
	setShouldShow(false);
}

export { getSuites, SuiteInput, getSuiteNote };