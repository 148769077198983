const Modal = ({shouldShow, onRequestClose,children}:any) => {

	return shouldShow ? (<>
		<div className='modalbackground' onClick={onRequestClose}>
			<div className='modalbody' onClick={e => e.stopPropagation()}>
				<button className="closeX" onClick={onRequestClose}>X</button>
				{children}
			</div>
		</div></>) : null;
};


const ModalFull = ({shouldShow, onRequestClose,children}:any) => {

	return shouldShow ? (<>
		<div className='modalbackground' onClick={onRequestClose}>
			<div className='modalFullbody' onClick={e => e.stopPropagation()}>
				<button className="closeX" onClick={onRequestClose}>X</button>
				{children}
			</div>
		</div></>) : null;
};
export {Modal, ModalFull};