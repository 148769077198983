import {warning, error} from "../components/Notifications";
import {lang} from "../components/appLang.js";

const Lang = lang();

const randomNumberInRange = (min = 0, max = 100) => {
	return Math.floor(Math.random() * (max - min + 1)) + min;
};

const numberOnlyInput = (value, min, max, length) => {
	if ( value === null ) {
		return;
	}
	if ( isNaN(value) || isNaN(parseFloat(value)) ) {
		warning(Lang.onlyNumbers);
		value = value.replace(/[^0-9]/g, "");
	}
	else if ( min !== null && (Number(value) < min || Number(value) > max) ) {
		console.log(value);
		value = "";
		warning(Lang.between+" "+min+" "+Lang.and+" "+max);
	}
	
	return value;
};

const elapsedTime = (taskStart) => {
	if ( taskStart !== undefined ) {
		const parseStart = taskStart.split(" ");
		const minuteStart = Number(parseStart[0].split(":")[1]);
		let hourStart = Number(parseStart[0].split(":")[0]);
		if ( parseStart[1] === Lang.pm && hourStart !== 12 ) {
			hourStart = Number(hourStart+12);
		}
		else if ( parseStart[1] === Lang.am && hourStart === 12 ) {
			hourStart = 0;
		}
		const start = new Date();
		start.setHours(hourStart,minuteStart);
		const time = Date.now();
		const minutesToSet = Math.floor((time-Number(start.getTime()))/1000/60);
		if ( ! Number.isNaN(minutesToSet) ) {
			return minutesToSet;
		}
	}
	
	return 0;
};

const verifyTime = (startTime, endHour, endMinute, endAmpm, checkType = "passive") => {
	if ( endHour === "" || endMinute === "" ) {
		if ( checkType === "passive" ) {
			return "ok";
		}
		else {
			error(Lang.completeTime);
			return "notComplete";
		}
	}
	if ( endAmpm !== Lang.am && endAmpm !== Lang.pm ) {
		error(Lang.completeTime);
		return "notComplete";
	}
	if ( endHour < 1 || endHour > 12 ) {
		return "error";
	}
	if ( endMinute < 0 || endMinute > 59 ) {
		return "error";
	}
	if ( endAmpm === Lang.pm && endHour !== 12 ) {
		endHour=Number(endHour)+12;
	}
	if ( startTime === "" ) {
		return "ok";
	}
	if ( endAmpm === Lang.am && endHour === 12 ) {
		endHour=0;
	}
	endMinute=Number(endMinute);
	const parseStart = startTime.split(" ");
	const minuteStart = Number(parseStart[0].split(":")[1]);
	let hourStart = Number(parseStart[0].split(":")[0]);
	const currentDate = new Date;
	
	if ( parseStart[1] === Lang.pm && hourStart !== 12 ) {
		hourStart = Number(hourStart+12);
	}
	else if ( parseStart[1] === Lang.am && hourStart === 12 ) {
		hourStart = 0;
	}
	console.log(hourStart,":",minuteStart);
	const endMinutes = Number(endHour)*60+Number(endMinute);
	const currentMinutes = Number(currentDate.getHours())*60+Number(currentDate.getMinutes())+5;
	console.log("hour end",endHour);
	//console.log(endMinutes+" "+currentMinutes);
	//console.log(hourStart*60+minuteStart+" "+endMinutes);
	if ( hourStart*60+minuteStart >= Number(endHour)*60+Number(endMinute) ) {
		return "badEnd";
	}
	else if ( Number(endHour)*60+Number(endMinute) >= Number(currentDate.getHours())*60+Number(currentDate.getMinutes())+5 ) {
		return "badStart";
	}
	return "ok";	
};

function checkForLang (input) {
	if ( input in Lang ) {
		return Lang[input];
	}
	return input;
}

export {randomNumberInRange, numberOnlyInput, verifyTime, elapsedTime, checkForLang}; 