import { useEffect, useState } from "react";
import { db } from "../services/db";
import { ButtonComponent, Checkbox } from "./FormComponents";
import { error } from "./Notifications";
import {lang} from "./appLang.js";
const Lang = lang();

function showPropertyDetails (propertyDetails:any) {
	if ( propertyDetails ) {
		return(<><div className="title">{propertyDetails.street}, {propertyDetails.city}</div>
			<div className="indent">{Lang.meterTypeLabel}
				<div className="indent">
					{ propertyDetails.meter_electric === true ? ( <div>{Lang.electric}</div>) : null}
					{ propertyDetails.meter_gas === true ? ( <div>{Lang.gas}</div>) : null}
					{ propertyDetails.meter_water === true ? ( <div>{Lang.water}</div>) : null}
					{ propertyDetails.meter_water_hot === true ? ( <div>{Lang.hot_water}</div>) : null}
					{ propertyDetails.meter_btu === true ? ( <div>{Lang.btu}</div>) : null}
					{ propertyDetails.pipe_system === 2 && propertyDetails.meter_btu === true ? ( <div className="indent">{Lang.twoPipe}</div>) : null}
					{ propertyDetails.pipe_system === 4 && propertyDetails.meter_btu === true ? ( <div className="indent">{Lang.fourPipe}</div>) : null}
				</div>
			</div>
		</>);
	}
	
	
}

const PropertyDetails = ({taskResult, onRequestClose, setPropertyDetails}:any) => {
	const [meter_electric, setMeter_electric] = useState<boolean>(false);
	const [meter_gas, setMeter_gas] = useState<boolean>(false);
	const [meter_water, setMeter_water] = useState<boolean>(false);
	const [meter_water_hot, setMeter_water_hot] = useState<boolean>(false);
	const [meter_btu, setMeter_btu] = useState<boolean>(false);
	const [pipe_system, setPipe_system] = useState(2);
	const [propDetails, setPropDetails] = useState<any>([]);
	const [twoPipeCheck, setTwoPipeCheck] = useState<boolean>(true);
	const [fourPipeCheck, setFourPipeCheck] = useState<boolean>(false);
	const [propAddress, setPropAddress] = useState("");
	
	useEffect (() => {
		PropertyDetailsFound(taskResult).then((result) => {
			setPropDetails(result);
			//setPropAddress(result.street+", "+result.city);
		});
	},[]);
	
	useEffect (() => {
		if ( propDetails !== undefined && propDetails.length !== 0 ) {
			if ( "id" in propDetails ) {
				setMeter_electric(propDetails.meter_electric);
				setMeter_gas(propDetails.meter_gas);
				setMeter_water(propDetails.meter_water);
				setMeter_water_hot(propDetails.meter_water_hot);
				setMeter_btu(propDetails.meter_btu);
				setPipe_system(propDetails.pipe_system);
				if ( propDetails.pipe_system === 2 ) {
					setTwoPipeCheck(true);
					setFourPipeCheck(false);
				}
				else if  ( propDetails.pipe_system === 4 ) {
					setTwoPipeCheck(false);
					setFourPipeCheck(true);
				}
			}
			setPropAddress(propDetails.street+", "+propDetails.city);
		}
		
	},[propDetails]);

	

	async function savePropertyDetails() {
		const updated_at = new Date().toISOString();
		if ( meter_btu === false && meter_electric === false && meter_gas === false && meter_water === false && meter_water_hot === false ) {
			error(Lang.meterManditory);
			return;
		}
		if ( meter_btu === true && pipe_system === null ) {
			error(Lang.mustBTUPipe);
			return;
		}
		await db.property_details.put({
			id: Number(taskResult.location),
			client_id: Number(taskResult.client_id),
			updated_at: updated_at,
			meter_electric: meter_electric,
			meter_gas: meter_gas,
			meter_water: meter_water,
			meter_water_hot: meter_water_hot,
			meter_btu: meter_btu,
			pipe_system: pipe_system
		});
		PropertyDetailsFound(taskResult).then((result) => {
			setPropDetails(result);
			setPropertyDetails(result);
		});
		onRequestClose();
	}

	function clickTwoPipe () {
		setTwoPipeCheck(true);
		setFourPipeCheck(false);
		setPipe_system(2);
	}

	function clickFourPipe () {
		setTwoPipeCheck(false);
		setFourPipeCheck(true);
		setPipe_system(4);
	}

	return <><div className="title">{propAddress}</div>
		<div className="indent">{Lang.meterTypeLabel}
			<div className="indent">
				<Checkbox label={Lang.electric} checked={Boolean(meter_electric)} name="electric"  formUpdate={(value: boolean) => setMeter_electric(value)}/>
				<Checkbox label={Lang.gas} checked={meter_gas} name="gas" formUpdate={(value: boolean) => setMeter_gas(value)}/>
				<Checkbox label={Lang.water} name="water" checked={meter_water} formUpdate={(value: boolean) => setMeter_water(value)}/>
				<Checkbox label={Lang.hot_water} name="hot_water" checked={meter_water_hot} formUpdate={(value: boolean) => setMeter_water_hot(value)}/>
				<Checkbox label={Lang.btu} name="btu" checked={meter_btu} formUpdate={(value: boolean) => setMeter_btu(value)}/>
				{ meter_btu ? (<><div className="indent">
					<div>
						<div className="radioButtons">
							<input 
								name="twoPipe"
								type="radio"
								onClick={() => {clickTwoPipe();}}
								className="radio"
								value="2"
								checked={twoPipeCheck}
								readOnly/>
						</div>{Lang.twoPipe}
					</div>
					<div>
						<div className="radioButtons">
							<input 
								name="fourPipe"
								type="radio"
								onClick={() => {clickFourPipe();}}
								className="radio"
								value="4"
								checked={fourPipeCheck}
								readOnly/>
						</div>{Lang.fourPipe}
					</div>	
				</div></>) : null}
			</div>
		</div>
		<ButtonComponent action={() => {savePropertyDetails();}} buttonText="Save "/>
	</>;
};

async function PropertyDetailsFound(taskResult: any) {
	if (taskResult.client_id) {
		console.log(taskResult);
		const details = await db.property_details.where({client_id: taskResult.client_id,id: taskResult.location}).toArray();
		const propLocation = await db.properties.where({client_id: taskResult.client_id,id: taskResult.location}).toArray();
		if ( details[0] !== undefined ) {
			return {...details[0], "street": propLocation[0].street1, "city": propLocation[0].city};
		}
		else {
			return {"street": propLocation[0].street1, "city": propLocation[0].city};
		}
	}
}



export {PropertyDetails, PropertyDetailsFound, showPropertyDetails };