import React, { useState, useEffect } from "react";
import Webcam from "react-webcam";
import {lang} from "./appLang.js";
import { ButtonComponent } from "./FormComponents";
import { success, warning } from "./Notifications.js";

const Lang = lang();

const FACING_MODE_USER = { facingMode: "user" }; //Front Camera
const FACING_MODE_ENVIRONMENT = { facingMode: "environment" }; //Back Camera

const Camera = ({saveImage, updateJob}:any) => {
	const webcamRef = React.useRef<Webcam>(null);
	const [image, setImage] = useState<any>(null);
	const [videoConstraints, setVideoConstraints] = useState(FACING_MODE_ENVIRONMENT);
	const [cameraFound, setCameraFound] = useState(true);
	const [numberCamera, setNumberCamera] = useState(0);
	const [mediaDevices, setMediaDevies] = useState<any>(null);

	useEffect (() => {
		if ( window.innerHeight < window.innerWidth ) {
			const widthValue = window.innerHeight+"px";
			const element = document.getElementById("reader");
			if ( element !== null ) {
				element.style.width = widthValue;
			}
			const element2 = document.getElementById("readerButton");
			if ( element2 !== null ) {
				element2.style.width = window.innerWidth*.9+"px";
			}
			const element3 = document.getElementById("photoTitle");
			if ( element3 !== null ) {
				element3.style.width = window.innerWidth*.9+"px";
			}
		}
		getListOfVideoInputs().then((values) => {
			setMediaDevies(values);
		});
		

	},[]);

	useEffect (() => {
		if ( mediaDevices !== null ) {
			if ( mediaDevices.length === 1 ) {
				if ( mediaDevices[0].deviceId === "" ) {
					warning(Lang.noCamera);
					setCameraFound(false);
					setNumberCamera(0);
					for ( let i=0;i<10;i++) {
						if ( numberCamera !== 0 ) {
							i = 10;
						}
						else {
							setTimeout(() => {getListOfVideoInputs();},500);
						}
					}
				}
				else {
					setCameraFound(true);
					setNumberCamera(1);
				}
			}
			else {
				setNumberCamera(mediaDevices.length);
				setCameraFound(true);
			}
		}
		
	},[mediaDevices]);

	const getListOfVideoInputs = async () => {
		const mediaDevices = await navigator.mediaDevices.enumerateDevices();
		if ( mediaDevices !== undefined ) {
			setMediaDevies(mediaDevices.filter(device => device.kind === "videoinput"));
		}
		return mediaDevices.filter(device => device.kind === "videoinput");
	};

	const switchCamera = async () => {
		const videoInputList = await getListOfVideoInputs();
		if (videoInputList.length > 1) {
			const currectVideoConstraints = { ...videoConstraints };

			// If the current constraint is the front camera, switch to the back camera.
			if (JSON.stringify(currectVideoConstraints) === JSON.stringify(FACING_MODE_USER)) {
				setVideoConstraints(FACING_MODE_ENVIRONMENT);
			}
			// If the current constraint is the back camera, switch to the front camera.
			if (JSON.stringify(currectVideoConstraints) === JSON.stringify(FACING_MODE_ENVIRONMENT)) {
				setVideoConstraints(FACING_MODE_USER);
			}
		}
		else if ( videoInputList.length === 1 ) {
			warning(Lang.oneCamera);
		} 
		else {
			warning(Lang.noCamera);
		}
	};

	const capture = React.useCallback(() => {
		if ( webcamRef.current !== null ) {
			const imageSrc = webcamRef.current.getScreenshot();
			setImage(imageSrc);
		}
	}, [webcamRef, setImage]);

	const retake = () => setImage(null);

	const upload = () => {
		// Do something with the image source, such as upload it to a server
		success(Lang.uploadPhotoSuccess);
		saveImage(image);
		setImage(null);
		updateJob();
	};
	
	return (<>
		{cameraFound ? (<div id="Scanner" className="blockSection">
			<div id="photoTitle" className="centre">
				<h3>{Lang.cameraTitle}</h3>
			</div>
			<div id="reader" className="centre">
				{image ? (<div>
					<img src={image} alt="Captured" />
				</div>
				) : (
					<Webcam
						className="photoSection"
						audio={false}
						ref={webcamRef}
						screenshotFormat="image/jpeg"
						videoConstraints={videoConstraints}
					/>
				)}
			</div>
			<div id="readerButton" className="centre">
				{image ? (
					<>
						<ButtonComponent action={() => {retake();}} buttonText={Lang.retakePicture}/>
						<ButtonComponent action={() => {upload();}} buttonText={Lang.savePhoto}/>
					</>
				) : (
					<>
						{ numberCamera > 1 ? (<ButtonComponent action={() => {switchCamera();}} buttonText={Lang.switchCamera}/>) : null }
						<ButtonComponent action={() => {capture();}} buttonText={Lang.takePhoto}/>
					</>
				)}
			</div>
		</div>) 
			: (<>
				<h3 className="centre">{Lang.noCamera}</h3>
				<div className="centre">{Lang.noCameraMessage}</div>
			</>)}
	</>);
};
export {Camera};