import { useEffect } from "react";
import {lang} from "./appLang.js";
import packageJson from "../../package.json";

const Lang = lang();

const getOnLineStatus = () =>
	typeof navigator !== "undefined" && typeof navigator.onLine === "boolean"
		? navigator.onLine
		: true;

const NavigatorOnLine = ({setStatus}:any) => {

	const setOnline = () => setStatus(true);
	const setOffline = () => setStatus(false);

	useEffect(() => {
		window.addEventListener("online", setOnline);
		window.addEventListener("offline", setOffline);

		return () => {
			window.removeEventListener("online", setOnline);
			window.removeEventListener("offline", setOffline);
		};
	}, []);

	return (<></>);
};

const StatusDiv = ({onlineStatus}:any) => {
	return (<div className="statusDiv">
		<div className="floatLeft">
			<div className="statusDivTitle">{Lang.appName} - {packageJson.version}</div>
		</div>
		<div className="floatRight">
			{onlineStatus ? (<div className="connectedDiv"><div className="green inblock">&#9679;</div> {Lang.online}</div>) : (<div className="connectedDiv"><div className="red inblock">&#9679;</div> {Lang.offline}</div>)}
		</div>
	</div>);
};

export { NavigatorOnLine, getOnLineStatus, StatusDiv };