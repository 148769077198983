import { info, success, warning, error } from "../components/Notifications";
import {lang} from "../components/appLang.js";
import {db} from "../services/db.tsx";

const Lang = lang();
function getLocate(workLogKey) {
	if (!navigator.geolocation) {
		warning(Lang.geoBrowserSupport);
	} else {
		navigator.geolocation.getCurrentPosition(successQuery, errorQuery);
	}

} 

async function successQuery(position) {
	const latitude = position.coords.latitude;
	const longitude = position.coords.longitude;
	const altitude = position.coords.altitude;
	const queryStamp = new Date().toISOString();
	try {
		await db.geoLocation.add({lat: latitude,
			lon: longitude,
			alt: altitude,
			worklog_id: 0,
			timestamp: queryStamp
		});
	}
	catch(error) {
		error(Lang.geoGetFail);
	}
}

function errorQuery() {
	error(Lang.geoGetFail);
}

async function successGeoCall (position,openTask) {
	const worklogIdStorage = localStorage.getItem("currentWorklog");
	const loggedIn = localStorage.getItem("loggedIn");
	console.log(loggedIn);
	if ( loggedIn === "true" ) {
		let worklogId = 0;
		if ( openTask && openTask !== 0 ) {
			worklogId = openTask;
		}
		if ( worklogIdStorage && worklogId === 0 ) {
			const worklogIdParts = worklogIdStorage.split(",");
			worklogId = worklogIdParts[0];
			const storageDate = new Date(worklogIdParts[1]);
			const todayDate = new Date();
			if ( todayDate.toDateString() !== storageDate.toDateString() ) {
				worklogId = 0;
			}
		}
		if ( Number(worklogId) !== 0 ) {
			const latitude = position.coords.latitude;
			const longitude = position.coords.longitude;
			const altitude = position.coords.altitude;
			const queryStamp = new Date().toISOString();
			try {
				await db.geoLocation.add({
					lat: latitude,
					lon: longitude,
					alt: altitude,
					worklog_id: worklogId,
					timestamp: queryStamp
				});
			}
			catch(error) {
				console.log(Lang.geoGetFail);
			}
			console.log("Got geoLocation");
		}
	}
	
}

function errorGeoCall() {
	console.log(Lang.geoGetFail);
}

export {getLocate, successGeoCall, errorGeoCall};